import { TokenResponse } from "@okta/okta-auth-js";
import { authClient } from "api/baseOktaService";

import { UserLoginDto } from "./dto";

export class OktaAuthService {
	async checkIfUseSignedIn(): Promise<boolean> {
		await authClient.start();
		return await authClient.isAuthenticated();
	}

	async signIn({ email, password }: UserLoginDto): Promise<TokenResponse> {
		const result = await authClient.signInWithCredentials({
			username: email,
			password
		});
		const response = {} as TokenResponse;

		if (result.status === "SUCCESS") {
			await authClient.token.getWithRedirect({
				//responseType: ["token", "id_token"],
				sessionToken: result.sessionToken
				// await authClient.token
				// 	.getWithoutPrompt({
				// 		responseType: ["token", "id_token"],
				// 		sessionToken: result.sessionToken
				// 	})
				// 	.then(function (res) {
				// 		authClient.tokenManager.setTokens(res.tokens);
				// 		response = res;
			});
		}

		return response;
	}

	async getTokens(sessionToken: string): Promise<TokenResponse> {
		let response = {} as TokenResponse;
		await authClient.token
			.getWithoutPrompt({
				responseType: ["token", "id_token"],
				sessionToken: sessionToken
			})
			.then(function (res) {
				authClient.tokenManager.setTokens(res.tokens);
				response = res;
			});
		return response;
	}

	async handleRedirect(): Promise<TokenResponse> {
		if (authClient.isLoginRedirect()) {
			// Parse token from redirect url
			const data = await authClient.token.parseFromUrl();
			authClient.tokenManager.setTokens(data.tokens);
			return data;
		}

		return {} as TokenResponse;
	}

	async signOut(): Promise<void> {
		await authClient.signOut();
		await authClient.stop();
	}
}

export const oktaService = new OktaAuthService();
