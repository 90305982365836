import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "api/baseApiService";
import { Language } from "models";

import { URLS } from "../services.constants";

export class LanguagesService extends BaseApiService<
	Language,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
}

export const languagesService = new LanguagesService(URLS.LANGUAGES);
