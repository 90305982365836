import { FC, ReactNode } from "react";

import { Box, Typography } from "@mui/material";
import DynamicFooter from "components/atoms/DynamicFooter";
import usePrefix from "hooks/usePrefix";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
	children: ReactNode;
}

const SignInFooter: FC<Props> = ({ children }) => {
	const { t } = useTranslation();
	const { linkPrefix } = usePrefix();

	return (
		<DynamicFooter>
			<Box margin={{ xs: 2, sm: 0 }} zIndex={1}>
				<Typography variant="textMedium" color="common.black" marginRight={1}>
					{t("signInPage.dontHaveAccount")}
				</Typography>
				<Typography variant="linkMedium" color="primary.main" component={Link} to={`${linkPrefix}/self-registration`}>
					{t("signInPage.buttons.signUp")}
				</Typography>
			</Box>
			{children}
		</DynamicFooter>
	);
};

export default SignInFooter;
