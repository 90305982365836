import { CustomUserClaims } from "@okta/okta-auth-js";

export interface OktaStoreModel {
	idToken: IdToken;
	accessToken: AccessToken;
}

export interface IdToken {
	idToken: string;
	claims: Claims;
	expiresAt: number;
	scopes: string[];
	authorizeUrl: string;
	issuer: string;
	clientId: string;
}

export type Claims = CustomUserClaims & {
	sub: string;
	email: string;
	ver: number;
	iss: string;
	aud: string;
	iat: number;
	exp: number;
	jti: string;
	amr: string[];
	idp: string;
	nonce: string;
	auth_time: number;
	at_hash: string;
	lastName: string;
	country: string;
	firstName: string;
	digital_id: string;
	language: string;
	title: string;
	login: string;
	primaryEmail: string;
	preferredLanguage?: string;
};

export interface AccessToken {
	accessToken: string;
	claims: Claims2;
	expiresAt: number;
	tokenType: string;
	scopes: string[];
	authorizeUrl: string;
	userinfoUrl: string;
}

export interface Claims2 {
	ver: number;
	jti: string;
	iss: string;
	aud: string;
	iat: number;
	exp: number;
	cid: string;
	uid: string;
	scp: string[];
	auth_time: number;
	sub: string;
}

export const OktaErrorCodes = {
	AUTH_FAILED: "E0000004"
};
