import { FC, useState } from "react";

import { IconButton, InputAdornment, TextFieldProps, useTheme } from "@mui/material";

import { EyeOff, EyeOn } from "assets/images";

import TextFieldInput from "../TextFieldInput";

type Props = TextFieldProps & {
	error: boolean;
	errorMessage?: string | null;
	validMessage?: string;
};

const PasswordInput: FC<Props> = ({ validMessage = "", ...rest }) => {
	const theme = useTheme();

	const [showPassword, setShowPassword] = useState(false);

	return (
		<TextFieldInput
			variant="outlined"
			isPassword
			validMessage={validMessage}
			type={showPassword ? "text" : "password"}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							onClick={() => setShowPassword(s => !s)}
							onMouseDown={e => e.preventDefault()}
							edge="end"
							size="large"
						>
							{showPassword ? (
								<EyeOn fill={theme.palette.primary.main} />
							) : (
								<EyeOff fill={theme.palette.customShades.grey1} />
							)}
						</IconButton>
					</InputAdornment>
				)
			}}
			{...rest}
		/>
	);
};

export default PasswordInput;
