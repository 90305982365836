import { FC, ReactNode } from "react";

import { Grid } from "@mui/material";
import SideInfo from "components/atoms/SideInfo";

interface Props {
	children: ReactNode;
	containerFooter?: ReactNode;
}

const PageWithSideInfo: FC<Props> = ({ children, containerFooter }) => (
	<Grid container sx={{ height: "100%" }}>
		<Grid item md={3.7} display={{ xs: "none", md: "flex" }} component="aside">
			<SideInfo />
		</Grid>
		<Grid
			item
			component="main"
			display="grid"
			gridTemplateRows={containerFooter ? "auto 80px" : "auto"}
			xs={12}
			md={8.3}
			sx={{
				height: "100vh",
				overflow: "scroll",
				marginBottom: "80px"
			}}
		>
			<Grid item padding={{ xs: "0px 24px", sm: "0px 72px", md: "96px 96px 48px", lg: "96px 180px 48px" }}>
				{children}
			</Grid>
			{!!containerFooter && containerFooter}
		</Grid>
	</Grid>
);

export default PageWithSideInfo;
