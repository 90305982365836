import { useState } from "react";

import { Box, Typography } from "@mui/material";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import PageWithLogo from "components/templates/PageWithLogo";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";

import { Link } from "react-router-dom";

import animationData from "assets/animations/email_sent.json";
import animationDataCropped from "assets/animations/email_sent_cropped.json";

const defaultOptions = {
	loop: false,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

const defaultOptionsForCropped = {
	loop: false,
	autoplay: true,
	animationData: animationDataCropped,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

const SucceedSent = () => {
	const { t } = useTranslation();
	const [animationCompleted, setAnimationCompleted] = useState(false);
	return (
		<PageWithLogo>
			<Box
				maxWidth={640}
				alignSelf="center"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				margin="auto"
				alignItems={"center"}
				padding={3}
			>
				{!animationCompleted ? (
					<Lottie
						options={defaultOptions}
						height={240}
						width={252}
						eventListeners={[
							{
								eventName: "complete",
								callback: () => setAnimationCompleted(true)
							}
						]}
					/>
				) : (
					<Lottie options={defaultOptionsForCropped} height={240} width={252} />
				)}
				<Typography variant="h1Headline" color="tertiary.main" align="center">
					{t("forgotPasswordPage.emailSent.title")}
				</Typography>
				<Typography variant="textMedium" color="customShades.grey1" mt={3} align="center">
					{t("forgotPasswordPage.emailSent.description")}
				</Typography>
				<Link to="/signIn">
					<PrimaryButton variant="contained" marginTop={40}>
						{t("forgotPasswordPage.emailSent.buttonTitle")}
					</PrimaryButton>
				</Link>
			</Box>
		</PageWithLogo>
	);
};

export default SucceedSent;
