import { InputLabel as MInputLabel } from "@mui/material";
import styled from "styled-components";

const labelTypography = {
	fontFamily: "InterVariable",
	fontSize: "13px",
	fontWeight: "700",
	fontStyle: "normal",
	textAlign: "start",
	lineHeight: "16px",
	textTransform: "uppercase",
	zIndex: 0
};

const StyledMInputLabel = styled(MInputLabel)`
	position: relative !important;
	color: ${({ theme }) => `${theme.palette.customShades.grey1} !important`};
	margin-bottom: 8px;
`;

const InputLabel = ({ children, helpText = "", ...props }) => (
	<StyledMInputLabel {...props} sx={{ ...labelTypography }}>
		{children} <span>{helpText}</span>
	</StyledMInputLabel>
);

export default InputLabel;
