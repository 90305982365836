import { Alert as MuiAlert, useTheme } from "@mui/material";

import { IconAlert } from "assets";
const Alert = ({ children, margin }) => {
	const theme = useTheme();
	return (
		<MuiAlert
			icon={<IconAlert fill={theme.palette.error.main} />}
			sx={{
				margin: `${margin}`,
				alignItems: "center",
				".MuiAlert-icon": {
					minWidth: "25px",
					marginInlineEnd: "12px"
				},
				"&.MuiPaper-root": {
					borderColor: "error.main",
					borderWidth: "1px",
					borderStyle: "solid"
				},
				".MuiTypography-root": {
					marginTop: 1
				}
			}}
			severity="error"
		>
			{children}
		</MuiAlert>
	);
};

export default Alert;
