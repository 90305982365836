import { useCallback, useState } from "react";

import { signUpRequest } from "api/features/Auth/authApi";
import EmailVerification from "components/organisms/SignUp/EmailVerification";
import FormOne from "components/organisms/SignUp/FormOne";
import FormTwo from "components/organisms/SignUp/FormTwo";

export interface IFormOne {
	username: string;
	password: string;
	confirmPassword: string;
	termsAndPrivacy: boolean;
}

export interface IFormTwo {
	title?: string;
	firstName: string;
	lastName: string;
	preferredLanguage: string;
	countryCode: string;
	recaptchaGoogle: string;
}

const SignUp = () => {
	const [formOneState, setFormOneState] = useState<IFormOne>({
		username: "",
		password: "",
		confirmPassword: "",
		termsAndPrivacy: false
	});
	const [formTwoState, setFormTwoState] = useState<IFormTwo>({
		title: "",
		firstName: "",
		lastName: "",
		preferredLanguage: "",
		countryCode: "",
		recaptchaGoogle: ""
	});
	const [activeStep, setActiveStep] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [buttonClick, setButtonClick] = useState<boolean>(false);

	const submitAllForms = useCallback(async () => {
		const user = { ...formOneState, ...formTwoState };
		await signUpRequest(user);
		setIsLoading(false);
		setActiveStep(activeStep + 1);
	}, [activeStep, formOneState, formTwoState]);

	return (
		<>
			{activeStep === 0 && (
				<FormOne
					setActiveStep={setActiveStep}
					formState={formOneState}
					setFormState={setFormOneState}
					buttonClick={buttonClick}
					setButtonClick={setButtonClick}
				/>
			)}

			{activeStep === 1 && (
				<FormTwo
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					formState={formTwoState}
					setFormState={setFormTwoState}
					submitAllForms={submitAllForms}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
				/>
			)}
			{activeStep === 2 && <EmailVerification email={formOneState.username} />}
		</>
	);
};

export default SignUp;
