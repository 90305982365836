import { FC } from "react";

import { ButtonProps } from "@mui/material";
import Button from "components/atoms/Button";

import { buttonTypography } from "theme/buttonTypography";

interface Props extends ButtonProps {
	marginTop?: number;
	maxWidth?: number | string | { xs: string | number; md: string | number };
}

const PrimaryButton: FC<Props> = ({ children, marginTop = 0, disabled = false, maxWidth = "auto", ...rest }) => {
	return (
		<Button
			variant="contained"
			size="large"
			sx={{
				...buttonTypography.large,
				width: maxWidth,
				height: "48px",
				padding: "14px 23.5px",
				maxHeight: "48px",
				minWidth: "200px",
				marginTop: `${marginTop}px`,
				...(disabled && {
					backgroundColor: theme => theme.palette.primary.main,
					color: theme => theme.palette.common.white,
					opacity: 0.4
				})
			}}
			{...rest}
		>
			{children}
		</Button>
	);
};

export default PrimaryButton;
