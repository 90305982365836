import { useCallback, useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";

import { signUpRequest } from "api/features/Auth/authApi";
import { verifyToken } from "api/features/User/userApi";
import EmailActivated from "components/organisms/SignUpConnect/EmailActivated";
import FormOne from "components/organisms/SignUpConnect/FormOne";
import FormTwo from "components/organisms/SignUpConnect/FormTwo";
import { useNavigate, useParams } from "react-router-dom";

export interface IFormOne {
	username: string;
	password: string;
	confirmPassword: string;
	termsAndPrivacy: boolean;
	emailCommunication: boolean;
}

export interface IFormTwo {
	title?: string;
	firstName: string;
	lastName: string;
	preferredLanguage: string;
	countryCode: string;
	recaptchaGoogle: string;
}

export interface IEmailValidation {
	url: string;
	application: string;
}
const SignUpConnect = () => {
	const { token } = useParams();
	const navigate = useNavigate();

	const [formOneState, setFormOneState] = useState<IFormOne>({
		username: "",
		password: "",
		confirmPassword: "",
		termsAndPrivacy: false,
		emailCommunication: false
	});
	const [formTwoState, setFormTwoState] = useState<IFormTwo>({
		title: "",
		firstName: "",
		lastName: "",
		preferredLanguage: "",
		countryCode: "",
		recaptchaGoogle: ""
	});
	const [emailValidationState, setEmailValidationState] = useState<IEmailValidation>({
		url: "",
		application: ""
	});
	const [activeStep, setActiveStep] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [buttonClick, setButtonClick] = useState<boolean>(false);
	const [validatingToken, setValidatingToken] = useState<boolean>(false);

	const getUserData = useCallback(async () => {
		setValidatingToken(true);

		const response = await verifyToken(token);
		if (response.code === 400) {
			return navigate("*");
		}
		setFormOneState(prevState => ({ ...prevState, username: response.email }));
		setFormTwoState(prevState => ({
			...prevState,
			firstName: response.firstName,
			lastName: response.lastName,
			preferredLanguage: response.lang,
			countryCode: response.cc
		}));
		setEmailValidationState(prevState => ({
			...prevState,
			url: response.url,
			application: response.application
		}));
		setValidatingToken(false);
	}, [navigate, token]);

	useEffect(() => {
		getUserData();
	}, [getUserData]);

	const submitAllForms = useCallback(async () => {
		const user = { ...formOneState, ...formTwoState, token: token };
		await signUpRequest(user);
		setIsLoading(false);
		setActiveStep(activeStep + 1);
	}, [activeStep, formOneState, formTwoState, token]);

	if (validatingToken) {
		return (
			<Box width="100%" height="50%" display="flex" alignItems="center" justifyContent="center">
				<CircularProgress size={100} />
			</Box>
		);
	}

	return (
		<>
			{activeStep === 0 && (
				<FormOne
					setActiveStep={setActiveStep}
					formOneState={formOneState}
					setFormOneState={setFormOneState}
					buttonClick={buttonClick}
					setButtonClick={setButtonClick}
				/>
			)}
			{activeStep === 1 && (
				<FormTwo
					isLoading={isLoading}
					submitAllForms={submitAllForms}
					activeStep={activeStep}
					formState={formTwoState}
					setIsLoading={setIsLoading}
					setFormState={setFormTwoState}
					setActiveStep={setActiveStep}
				/>
			)}
			{activeStep === 2 && <EmailActivated EmailActivatedState={emailValidationState} />}
		</>
	);
};

export default SignUpConnect;
