//import { OAuthResponseMode } from "@okta/okta-auth-js";

import { GLOBAL_CONSTANTS } from "../../constants";

export const config = {
	url: GLOBAL_CONSTANTS.OKTA_URL,
	issuer: GLOBAL_CONSTANTS.OKTA_ISSUER,
	clientId: GLOBAL_CONSTANTS.OKTA_CLIENT_ID,
	redirectUri: GLOBAL_CONSTANTS.OKTA_REDIRECT_URI,
	pkce: GLOBAL_CONSTANTS.OKTA_PKCE,
	//responseMode: GLOBAL_CONSTANTS.OKTA_RESPONSE_MODE as OAuthResponseMode,
	postLogoutRedirectUri: `${window.location.origin}/signOut`
};
