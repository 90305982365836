import { Box, Typography, useMediaQuery } from "@mui/material";
import { SuccessAnimationData } from "assets";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import PageWithLogo from "components/templates/PageWithLogo";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import { theme } from "theme";

const PasswordChanged = () => {
	const { t } = useTranslation();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<PageWithLogo>
			<Box
				maxWidth={640}
				alignSelf="center"
				display="flex"
				flexDirection="column"
				justifyContent="center"
				margin="auto"
				alignItems={"center"}
				padding={3}
			>
				<Lottie
					options={{
						loop: false,
						autoplay: true,
						animationData: SuccessAnimationData,
						rendererSettings: {
							preserveAspectRatio: "xMidYMid slice"
						}
					}}
					height={212}
					width={217.8}
				/>
				<Typography variant={isDesktop ? "h1Headline" : "h4Headline"} color="tertiary.main" align="center">
					{t("forgotPasswordPage.passwordChanged.title")}
				</Typography>
				<Typography variant="textMedium" color="customShades.grey1" mt={3} align="center">
					{t("forgotPasswordPage.passwordChanged.description")}
				</Typography>
				<Link to="/signIn">
					<PrimaryButton variant="contained" marginTop={40}>
						{t("forgotPasswordPage.passwordChanged.buttonTitle")}
					</PrimaryButton>
				</Link>
			</Box>
		</PageWithLogo>
	);
};

export default PasswordChanged;
