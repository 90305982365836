import { FC, ReactNode, useMemo } from "react";

import { Box, BoxProps, styled, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const MobileFooterContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0;
	gap: 8px;
`;

export const FooterContainer = styled(MobileFooterContainer)`
	position: fixed;
	bottom: 0;
	right: 0;
	padding: 16px 40px;
	border-top: ${({ theme }) => `1px solid ${theme.palette.customShades.light3}`};
	max-width: 69.166667%;
	height: 80px;
	gap: 16px;
`;

interface Props extends BoxProps {
	children: ReactNode;
	width?: string | number;
}

const DynamicFooter: FC<Props> = ({ children, width = "100%", ...rest }) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const Wrapper = useMemo(() => (isDesktop ? FooterContainer : MobileFooterContainer), [isDesktop]);

	return (
		<Wrapper bgcolor="common.white" flexDirection={{ xs: "column-reverse", md: "row" }} width={width} {...rest}>
			{children}
		</Wrapper>
	);
};

export default DynamicFooter;
