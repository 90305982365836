/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { userService } from "../../services/users";

export const getCurrentUserData = async () => {
	return await userService.getUserData();
};

export const changeUser = async data => {
	return await userService.changeUser(data).catch(e => e);
};

export const changePassword = async data => {
	return await userService.changePassword(data.oldPassword, data.newPassword);
};

export const requestResetPassword = async ({ username, recaptchaGoogle }) => {
	return await userService.requestResetPassword(username, recaptchaGoogle);
};

export const resetPassword = async data => {
	return await userService.resetPassword(data.recoveryToken, data.newPassword);
};

export const verifyToken = async data => {
	return await userService.verifyToken(data).catch(error => error);
};

export const checkTokenAlive = async (recoveryToken: string) => {
	return await userService.checkTokenAlive(recoveryToken);
};
