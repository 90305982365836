import { useEffect } from "react";

import { clearLocalStorage } from "api/features/Auth/authApi";
import Loader from "components/atoms/Loader";
import { useNavigate } from "react-router-dom";

const SignOut = () => {
	const navigate = useNavigate();

	useEffect(() => {
		clearLocalStorage();
		navigate("/signIn");
	}, [navigate]);

	return <Loader color="white" />;
};

export default SignOut;
