import { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { checkTokenAlive, resetPassword } from "api/features/User/userApi";
import PasswordInput from "components/molecules/Inputs/PasswordInput";
import PasswordRegexValidator from "components/molecules/PasswordRegexValidator";
import ButtonGroup from "components/organisms/ForgotPassword/ButtonGroup";
import FormWrapper from "components/templates/FormWrapper";
import usePrefix from "hooks/usePrefix";
import { isEqual } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ChangePasswordFormValues, changePasswordValidationSchema } from "./constant";

const CreatePasswordForm = () => {
	const [isTakedaAccount, setIsTakedaAccount] = useState<boolean | null>();
	const { recoveryToken } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { linkPrefix } = usePrefix();

	useEffect(() => {
		if (recoveryToken) {
			checkTokenAlive(recoveryToken).then(({ result }) => {
				if (!result) navigate("/not-found");
				setIsTakedaAccount(result?.isTakedaAccount);
			});
		}
	}, [navigate, recoveryToken]);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorSummary, setErrorSummary] = useState<string | null>(null);

	const {
		handleSubmit,
		control,
		watch,
		trigger,
		formState: { isValid, isDirty }
	} = useForm<ChangePasswordFormValues>({
		mode: "onChange",
		resolver: yupResolver(changePasswordValidationSchema(t, !!isTakedaAccount)),
		delayError: 1000,
		defaultValues: {
			password: "",
			confirmPassword: ""
		}
	});

	const onSubmit = async (data: ChangePasswordFormValues) => {
		setIsLoading(true);
		try {
			await resetPassword({ recoveryToken, newPassword: data.password });
			navigate(`${linkPrefix}/forgot-password/password-changed`);
		} catch (err) {
			const e = err as { response: { message: string } };
			setErrorSummary(e.response.message);
		}
		setIsLoading(false);
	};

	const [password, confirmPassword] = watch(["password", "confirmPassword"]);

	const matchPassword = useMemo(() => isEqual(password, confirmPassword), [password, confirmPassword]);

	return (
		<FormWrapper
			title={t("forgotPasswordPage.createPassword.title")}
			description={t("forgotPasswordPage.createPassword.description")}
			onSubmit={handleSubmit(onSubmit)}
			errorSummary={errorSummary}
			footerBlock={
				<ButtonGroup
					submitTitle={t("forgotPasswordPage.createPassword.buttons.reset")}
					backTitle={t("forgotPasswordPage.createPassword.buttons.back")}
					loading={isLoading}
					dirty={isDirty}
					valid={isValid}
					onBack={() => navigate(-1)}
					onSubmit={handleSubmit(onSubmit)}
				/>
			}
		>
			<Controller
				name="password"
				control={control}
				render={({ field: { onChange, onBlur, ...rest }, fieldState: { error } }) => (
					<PasswordInput
						placeholder={t("forgotPasswordPage.createPassword.fields.password.placeholder")}
						label={t("forgotPasswordPage.createPassword.fields.password.label")}
						error={!!error}
						errorMessage={error ? error.message : null}
						onBlur={() => {
							trigger("confirmPassword");
							onBlur();
						}}
						onChange={(...p) => {
							onChange(...p);
							if (errorSummary) {
								setErrorSummary(null);
							}
						}}
						{...rest}
					/>
				)}
			/>
			<Controller
				name="confirmPassword"
				control={control}
				render={({ field: { onChange, ...rest }, fieldState: { error, invalid, isDirty } }) => (
					<PasswordInput
						placeholder={t("forgotPasswordPage.createPassword.fields.confirmPassword.placeholder")}
						label={t("forgotPasswordPage.createPassword.fields.confirmPassword.label")}
						error={!!error}
						errorMessage={error ? error.message : null}
						onChange={(...p) => {
							onChange(...p);
							if (errorSummary) {
								setErrorSummary(null);
							}
						}}
						{...rest}
						validMessage={
							!invalid && isDirty
								? t("forgotPasswordPage.createPassword.fields.confirmPassword.validation.matched")
								: undefined
						}
					/>
				)}
			/>
			{!!password.length && (
				<PasswordRegexValidator
					password={password}
					isTakedaEmail={!!isTakedaAccount}
					additionalPasswordRules={[
						{
							rule: matchPassword,
							description: t("passwordRules.shouldMatch")
						}
					]}
				/>
			)}
		</FormWrapper>
	);
};

export default CreatePasswordForm;
