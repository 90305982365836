import { FC } from "react";

import { Box, Modal, ModalProps, Typography, styled } from "@mui/material";
import { IconClose } from "assets";
import { theme } from "theme";

import { IOptions, StyledMenuItem } from "../Select/Select";

const Header = styled(Box)`
	background-color: ${props => props.theme.palette.customShades.light2};
	display: flex;
	padding: 16px;
	align-items: center;
	height: 48px;
	justify-content: space-between;
`;

const StyledModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 40px;
	width: 100%;
`;

const Container = styled(Box)`
	outline: none;
	border-radius: 8px;
	max-height: 480px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 360px;
	overflow: hidden;
`;
const Content = styled(Box)`
	background-color: ${props => props.theme.palette.common.white};
	max-height: calc(100% - 48px);
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	overflow-x: hidden;
	overflow-y: scroll;
`;

interface Props extends Omit<ModalProps, "onChange" | "onClose" | "children"> {
	title?: string;
	options: IOptions[];
	required?: boolean;
	onChange: (v: string) => void;
	onClose: () => void;
}

const SelectModal: FC<Props> = ({ open, onClose, title, options, required, placeholder, onChange }) => (
	<StyledModal open={open} onClose={onClose}>
		<Container>
			<Header>
				<Typography variant="linkMedium" color="tertiary.main">
					{title}
				</Typography>
				<IconClose onClick={onClose}></IconClose>
			</Header>
			<Content>
				{placeholder && (
					<StyledMenuItem
						value=""
						divider
						disabled={!!required}
						onClick={() => {
							onChange("");
							onClose();
						}}
						sx={{ ...theme.typography.textMedium }}
					>
						{placeholder}
					</StyledMenuItem>
				)}
				{options.map(({ value: _value, label }, index) => (
					<StyledMenuItem
						key={index}
						value={_value}
						selected={false}
						onClick={() => {
							onChange(_value as string);
							onClose();
						}}
						sx={{ ...theme.typography.textMedium }}
						divider
					>
						{label}
					</StyledMenuItem>
				))}
			</Content>
		</Container>
	</StyledModal>
);

export default SelectModal;
