import { useContext, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography, styled } from "@mui/material";
import { signInRequest } from "api/features/Auth/authApi";
import PasswordInput from "components/molecules/Inputs/PasswordInput";

import TextFieldInput from "components/molecules/Inputs/TextFieldInput";
import ButtonGroup from "components/organisms/ForgotPassword/ButtonGroup";
import FormWrapper from "components/templates/FormWrapper";
import { AppContext } from "contexts/app/AppProvider";
import usePrefix from "hooks/usePrefix";
import i18n from "i18n/config";
import { OktaErrorCodes } from "models";
import { Controller, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";

import { SignInFooter } from "./components";

import { SignInFormValues, loginValidationSchema } from "./constants";

const StyledContainerItems = styled(Box)`
	margin-top: 8px;
	display: flex;
	justify-content: flex-end;
`;

const SignIn = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { hasHistory } = useContext(AppContext);

	const { linkPrefix, defaultLanguage } = usePrefix();

	const [errorSummary, setErrorSummary] = useState<string | null>(null);
	const {
		handleSubmit,
		control,
		formState: { isValid, isDirty }
	} = useForm<SignInFormValues>({
		mode: "onChange",
		resolver: yupResolver(loginValidationSchema(t)),
		delayError: 1000,
		defaultValues: {
			email: "",
			password: ""
		}
	});
	useEffect(() => {
		i18n.changeLanguage(defaultLanguage);
	}, [defaultLanguage]);
	const onSubmit = async (data: SignInFormValues) => {
		setIsLoading(true);
		const response = await signInRequest({ username: data.email, ...data }, state => navigate("/profile", { state }));
		setIsLoading(false);
		if (response?.errorCode === OktaErrorCodes.AUTH_FAILED) {
			setErrorSummary(t("signInPage.Error401"));
		}
	};

	return (
		<FormWrapper
			title={t("signInPage.title")}
			onSubmit={handleSubmit(onSubmit)}
			errorSummary={errorSummary}
			footerBlock={
				<SignInFooter>
					<ButtonGroup
						onBack={() => navigate(-1)}
						isBackButtonEnabled={hasHistory}
						onSubmit={handleSubmit(onSubmit)}
						submitTitle={t("signInPage.buttons.signIn")}
						backTitle={t("signInPage.buttons.back")}
						dirty={isDirty}
						valid={isValid}
						loading={isLoading}
					/>
				</SignInFooter>
			}
		>
			<Controller
				name="email"
				control={control}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<TextFieldInput
						placeholder={t("signInPage.fields.email.placeholder")}
						variant="outlined"
						value={value}
						onChange={(...p) => {
							onChange(...p);
							if (errorSummary) {
								setErrorSummary(null);
							}
						}}
						label={t("signInPage.fields.email.label")}
						error={!!error}
						helperText={error ? error.message : null}
						type="email"
					/>
				)}
			/>
			<Controller
				name="password"
				control={control}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<PasswordInput
						value={value}
						label={t("signInPage.fields.password.label")}
						placeholder={t("signInPage.fields.password.placeholder")}
						onChange={(...p) => {
							onChange(...p);
							if (errorSummary) {
								setErrorSummary(null);
							}
						}}
						error={!!error}
						errorMessage={error ? error.message : null}
					/>
				)}
			/>
			<StyledContainerItems>
				<Typography
					display="inline"
					variant="linkMedium"
					color="primary"
					component={Link}
					to={`${linkPrefix}/forgot-password`}
				>
					{t("signInPage.forgotPassword")}
				</Typography>
			</StyledContainerItems>
		</FormWrapper>
	);
};

export default SignIn;
