import { isEqual } from "lodash";
import { TFunction } from "react-i18next";
import * as yup from "yup";

export interface ResetPasswordFormValues {
	email: string;
	recaptchaGoogle: string;
}

export interface ChangePasswordFormValues {
	password: string;
	confirmPassword: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resetPasswordValidationSchema = (t: TFunction<"translation", undefined>) =>
	yup.object().shape({
		email: yup
			.string()
			.email(t("forgotPasswordPage.forgotPassword.fields.email.validation.email"))
			.required(t("forgotPasswordPage.forgotPassword.fields.email.validation.required"))
			.typeError(t("forgotPasswordPage.forgotPassword.fields.email.validation.string")),
		recaptchaGoogle: yup
			.string()
			.required(t("forgotPasswordPage.forgotPassword.fields.recaptchaGoogle.validation.required"))
			.typeError(t("forgotPasswordPage.forgotPassword.fields.recaptchaGoogle.validation.string"))
	});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const changePasswordValidationSchema = (t: TFunction<"translation", undefined>, isTakedaAccount = false) =>
	yup.object().shape({
		password: isTakedaAccount
			? yup
					.string()
					.required(t("forgotPasswordPage.createPassword.fields.password.validation.required"))
					.min(15, t("forgotPasswordPage.createPassword.fields.password.validation.minimum15"))
			: yup
					.string()
					.required(t("forgotPasswordPage.createPassword.fields.password.validation.required"))
					.min(8, t("forgotPasswordPage.createPassword.fields.password.validation.min"))
					.test("pattern", t("forgotPasswordPage.createPassword.fields.password.validation.pattern"), value =>
						[/[a-z]/, /[A-Z]/, /[0-9]/].every(pattern => value && pattern.test(value))
					)
					.typeError(t("forgotPasswordPage.createPassword.fields.password.validation.string")),
		confirmPassword: yup
			.string()
			.required(t("forgotPasswordPage.createPassword.fields.confirmPassword.validation.required"))
			.typeError(t("forgotPasswordPage.createPassword.fields.confirmPassword.validation.string"))
			.when("password", (password, schema) =>
				schema.test(
					"matchPassword",
					t("forgotPasswordPage.createPassword.fields.confirmPassword.validation.notEqual"),
					v => isEqual(password, v)
				)
			)
	});
