export const buttonTypography = {
	large: {
		fontFamily: "InterVariable",
		fontSize: "16px",
		fontWeight: "700",
		fontStyle: "normal",
		textAlign: "center",
		lineHeight: "20px",
		textTransform: "uppercase"
	},
	medium: {
		fontFamily: "InterVariable",
		fontSize: "14px",
		fontWeight: "700",
		fontStyle: "normal",
		textAlign: "center",
		lineHeight: "16px",
		textTransform: "uppercase"
	},
	small: {
		fontFamily: "InterVariable",
		fontSize: "12px",
		fontWeight: "700",
		fontStyle: "normal",
		textAlign: "center",
		lineHeight: "16px",
		textTransform: "uppercase"
	}
};
