import { FC } from "react";

import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { CheckMark, CheckMarkFilled } from "assets/images";

const CheckItem: FC<{ isChecked: boolean; description: string }> = ({ isChecked, description }) => (
	<Box
		sx={{
			display: "inline-flex",
			alignItems: "center",
			svg: {
				marginInlineEnd: "17px"
			}
		}}
		mb={1}
	>
		{isChecked ? (
			<CheckMarkFilled height={15} width={15} fill="#ABB436" style={{ opacity: 1 }} />
		) : (
			<CheckMark height={15} width={15} fill="#A1A4AC" style={{ opacity: 0.18 }} />
		)}
		<Typography variant="textSmall" color={isChecked ? "tertiary.main" : "customShades.grey1"}>
			{description}
		</Typography>
	</Box>
);

type CheckFuncType = (p: string) => boolean;
type PasswordRuleType = { rule: CheckFuncType | boolean; description: string };
const defaultPasswordRules = (t): PasswordRuleType[] => [
	{
		rule: pwd => !!pwd && pwd.length > 7 && pwd.length < 21,
		description: t("passwordRules.beMore8Length")
	},
	{
		rule: pwd => Boolean(!!pwd && pwd.match(/[A-Z]/)),
		description: t("passwordRules.haveCapitalLetters")
	},
	{
		rule: pwd => Boolean(!!pwd && pwd.match(/[a-z]/)),
		description: t("passwordRules.haveLowCaseLetters")
	},
	{
		rule: pwd => Boolean(!!pwd && pwd.match(/\d/)),
		description: t("passwordRules.haveNumber")
	}
];

const exclusiveEmailsRule = (t): PasswordRuleType[] => [
	{
		rule: pwd => !!pwd && pwd.length > 14,
		description: t("passwordRules.minimum15")
	}
];

interface Props {
	password: string;
	isTakedaEmail?: boolean;
	additionalPasswordRules?: PasswordRuleType[];
}

const PasswordRulesChecker: FC<Props> = ({ password, isTakedaEmail, additionalPasswordRules = [] }) => {
	const { t } = useTranslation();

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Typography variant="linkSmall" color="tertiary.main" mb={1}>
				{t("passwordRules.title")}
			</Typography>
			{isTakedaEmail
				? [...exclusiveEmailsRule(t), ...additionalPasswordRules].map(({ rule, description }, i) => (
						<CheckItem
							key={i}
							isChecked={typeof rule === "boolean" ? (rule as boolean) : (rule as CheckFuncType)(password)}
							description={description}
						/>
				  ))
				: [...defaultPasswordRules(t), ...additionalPasswordRules].map(({ rule, description }, i) => (
						<CheckItem
							key={i}
							isChecked={typeof rule === "boolean" ? (rule as boolean) : (rule as CheckFuncType)(password)}
							description={description}
						/>
				  ))}
		</Box>
	);
};

export default PasswordRulesChecker;
