import { FC } from "react";

import { Grid, Typography, styled, useMediaQuery } from "@mui/material";
import usePrefix from "hooks/usePrefix";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { theme } from "theme";

const StyledWrapper = styled(Grid)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

const LegalLinksWrapper = styled(Grid)`
	display: flex;
	width: auto;
`;

const Footer: FC<{ fullWidth?: boolean }> = ({ fullWidth = true }) => {
	const { t } = useTranslation();
	const { defaultLanguage } = useParams();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const { linkPrefix } = usePrefix();

	return (
		<StyledWrapper
			container
			position={isDesktop ? "fixed" : "unset"}
			bottom={0}
			right={0}
			width={{ xs: "100%", md: fullWidth ? "100%" : "66.7%" }}
			padding={{ md: "23px 32px", xs: 3 }}
			height={{ md: "64px", xs: "100px" }}
			bgcolor="common.white"
		>
			<Grid item md={8} xs={12} order={{ md: 1, xs: 2 }}>
				<Typography variant="textSmall" color="customShades.grey1">
					&copy; {t("footer.copy")}
				</Typography>
			</Grid>
			<LegalLinksWrapper
				item
				md={4}
				xs={12}
				justifyContent={{ xs: "flex-start", md: "flex-end" }}
				order={{ md: 2, xs: 1 }}
				dir={defaultLanguage === "he" ? "rtl" : "ltr"}
				gap={3}
			>
				<Typography
					variant="linkSmall"
					color="tertiary.main"
					target="_blank"
					component={Link}
					to={`${linkPrefix}/terms-and-conditions`}
				>
					{t("footer.termOfUseLinkTitle")}
				</Typography>
				<Typography
					variant="linkSmall"
					color="tertiary.main"
					target="_blank"
					component={Link}
					to={`${linkPrefix}/privacy-notice`}
				>
					{t("footer.privacyPolicyLinkTitle")}
				</Typography>
				<Typography variant="linkSmall" color="tertiary.main" target="_blank" component={Link} to={`${linkPrefix}/faq`}>
					{t("footer.faq")}
				</Typography>
			</LegalLinksWrapper>
		</StyledWrapper>
	);
};

export default Footer;
