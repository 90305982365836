import { Box, Grid, Typography } from "@mui/material";

import { theme } from "theme";

import { CheckMarkFilled as CheckMark } from "assets/images";

import { StyledSelectionContainer } from "./style";

interface SelectedListItemProps {
	SVG: React.SVGProps<SVGElement>;
	text: string;
}

const SelectedListItem = ({ SVG, text }: SelectedListItemProps) => {
	return (
		<>
			<StyledSelectionContainer item xs={12} md={5}>
				<Box display="flex" gap={2} alignItems="center">
					<>
						{SVG}
						<Typography variant="linkMedium" color="tertiary.main">
							{text}
						</Typography>
					</>
				</Box>
				<CheckMark fill={theme.palette.primary.main} opacity={1} style={{ alignSelf: "center" }} />
			</StyledSelectionContainer>
			<Grid item xs={0} md={1} />
		</>
	);
};

export default SelectedListItem;
