import { Box, Typography, styled } from "@mui/material";
import { IconDangerInfo } from "assets";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import SecondaryButton from "components/molecules/Buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { GLOBAL_CONSTANTS } from "../../../constants";

const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 40px 0px;
`;

const ConsentScreen = ({ changeStep }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Container>
			<IconDangerInfo />
			<Typography variant="h4Headline" color="tertiary.main" align="center" mt={5} mb={8}>
				{t("account.consent.heading")}
			</Typography>
			<PrimaryButton
				type="submit"
				variant="contained"
				size="large"
				onClick={() => window.open(GLOBAL_CONSTANTS.CONSENT_URL)}
				maxWidth={"100%"}
			>
				{t("account.consent.buttons.linkButton")}
			</PrimaryButton>
			<SecondaryButton
				variant="contained"
				fullWidth
				marginTop={8}
				onClick={() => {
					navigate("/profile");
					changeStep(1);
				}}
			>
				{t("account.consent.buttons.goBack")}
			</SecondaryButton>
		</Container>
	);
};

export default ConsentScreen;
