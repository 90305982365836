import { useCallback, useEffect, useState } from "react";

import { FormHelperText, Grid } from "@mui/material";
import { fetchCountries, fetchLanguages } from "api/features/Auth/authApi";
import ButtonFooter from "components/molecules/ButtonFooter";
import TextFieldInput from "components/molecules/Inputs/TextFieldInput";
import OutlinedSelectField from "components/molecules/OutlinedSelectField";
import { IOptions } from "components/molecules/Select";
import FormWrapper from "components/templates/FormWrapper";
import { Country, Language } from "models";
import { IFormTwo } from "pages/SignUp/SignUp";
import ReCaptcha from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { validateRequiredFields } from "utils/validateRequiredFields";

const FormTwo = ({ activeStep, setActiveStep, formState, setFormState, submitAllForms, isLoading, setIsLoading }) => {
	const { t } = useTranslation();
	const {
		control,
		handleSubmit,
		formState: { isValid, isDirty, errors }
	} = useForm<IFormTwo>({
		mode: "onChange",
		delayError: 1000,
		defaultValues: formState
	});
	const [languageOptions, setLanguageOptions] = useState<IOptions[]>([]);
	const [countryOptions, setCountriesOptions] = useState<IOptions[]>([]);
	const getLanguageOptions = useCallback(async () => {
		const apiData: Language[] = await fetchLanguages();
		setLanguageOptions(apiData?.map(language => ({ value: language.code, label: language.name })));
		return;
	}, []);

	const getCountriesOptions = useCallback(async () => {
		const apiData: Country[] = await fetchCountries();
		setCountriesOptions(apiData?.map(countries => ({ value: countries.code, label: countries.name })));
		return;
	}, []);

	useEffect(() => {
		getCountriesOptions();
		getLanguageOptions();
	}, [getCountriesOptions, getLanguageOptions]);
	const onSubmit = async (data: IFormTwo) => {
		setFormState(data);
		setIsLoading(true);
	};

	useEffect(() => {
		if (activeStep !== 0 && isLoading) {
			submitAllForms();
		}
	}, [activeStep, formState, isLoading, submitAllForms]);

	const isValidForm = validateRequiredFields(errors);

	return (
		<FormWrapper
			title={t("signUpPageRegular.title")}
			description={t("signUpPageRegular.subtitle")}
			footerBlock={
				<ButtonFooter
					isLoading={isLoading}
					primaryButtonText={t("buttons.signUp")}
					activeStep={activeStep}
					handlePrimaryButton={handleSubmit(onSubmit)}
					secondaryButton={activeStep > 0}
					handleSecondaryButton={() => setActiveStep(activeStep - 1)}
					disablePrimaryButton={!isDirty || !isValid}
				/>
			}
			errorSummary={!isValidForm ? t("signUpPageRegular.error") : undefined}
			onSubmit={handleSubmit(onSubmit)}
		>
			<Grid item xs={12}>
				<Grid item xs={12} md={6}>
					<OutlinedSelectField
						label={t("signUpPageRegular.nameTitle.label")}
						type="select"
						placeholder={t("signUpPageRegular.nameTitle.placeholder")}
						name="title"
						control={control}
						defaultValue=""
						options={[
							{ value: "Dr.", label: t("dropdowns.title.dr") },
							{ value: "Mr.", label: t("dropdowns.title.mr") },
							{ value: "Mrs.", label: t("dropdowns.title.mrs") }
						]}
					/>
				</Grid>
				<Grid container spacing={{ xs: 0, sm: 2 }}>
					<Grid item xs={12} md={6}>
						<Controller
							name="firstName"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextFieldInput
									placeholder={t("signUpPageRegular.firstName.placeholder")}
									inputProps={{
										maxLength: 100
									}}
									variant="outlined"
									value={value}
									onChange={e => {
										const v = e.target.value?.replace(/^\s*/gm, "");
										onChange(v);
									}}
									label={t("signUpPageRegular.firstName.label")}
									error={!!error}
									helperText={error ? error.message : null}
									type="text"
								/>
							)}
							rules={{
								required: true,
								minLength: {
									value: 2,
									message: t("dynamicForm.validation.minText")
								},
								maxLength: {
									value: 100,
									message: t("dynamicForm.validation.maxText")
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							name="lastName"
							control={control}
							defaultValue=""
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextFieldInput
									inputProps={{
										maxLength: 100
									}}
									placeholder={t("signUpPageRegular.lastName.placeholder")}
									variant="outlined"
									value={value}
									onChange={e => {
										const v = e.target.value?.replace(/^\s*/gm, "");
										onChange(v);
									}}
									label={t("signUpPageRegular.lastName.label")}
									error={!!error}
									helperText={error ? error.message : null}
									type="text"
								/>
							)}
							rules={{
								required: true,
								minLength: {
									value: 2,
									message: t("dynamicForm.validation.minText")
								},
								maxLength: {
									value: 100,
									message: t("dynamicForm.validation.maxText")
								}
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<OutlinedSelectField
							required
							label={t("signUpPageRegular.countryCode.label")}
							type="select"
							placeholder={t("signUpPageRegular.countryCode.placeholder")}
							name="countryCode"
							control={control}
							defaultValue=""
							rules={{ required: true }}
							options={countryOptions}
						/>
					</Grid>
					<Grid item xs={12}>
						<OutlinedSelectField
							required
							label={t("signUpPageRegular.preferredLanguage.label")}
							type="select"
							placeholder={t("signUpPageRegular.preferredLanguage.placeholder")}
							name="preferredLanguage"
							control={control}
							defaultValue=""
							rules={{ required: true }}
							options={languageOptions}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Controller
							control={control}
							name="recaptchaGoogle"
							rules={{ required: true }}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<>
									<ReCaptcha
										sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
										onChange={onChange}
										value={value}
										verifyCallback={handleSubmit(onSubmit)}
									/>
									{error ? (
										<FormHelperText error sx={{ margin: 0 }}>
											{error.message}
										</FormHelperText>
									) : null}
								</>
							)}
						/>
					</Grid>
				</Grid>
			</Grid>
		</FormWrapper>
	);
};

export default FormTwo;
