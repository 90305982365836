import { CircularProgress } from "@mui/material";
const Loader = ({ color }) => {
	return (
		<CircularProgress
			size={24}
			sx={{
				color: color,
				position: "absolute",
				top: "50%",
				left: "50%",
				marginTop: "-12px",
				marginLeft: "-12px"
			}}
		/>
	);
};

export default Loader;
