import { FC, ReactNode } from "react";

import { Grid } from "@mui/material";
import Footer from "components/atoms/Footer";
import Header from "components/atoms/Header";

interface Props {
	children: ReactNode;
	pushLogoLeft?: boolean;
	displayFooter?: boolean;
	rightBlock?: ReactNode;
}

const PageWithLogo: FC<Props> = ({ children, pushLogoLeft = false, displayFooter = true, rightBlock }) => (
	<Grid
		display="grid"
		height="100vh"
		maxHeight="100vh"
		gridTemplateRows={{
			md: `104px auto ${displayFooter ? "64px" : 0}`,
			xs: `${rightBlock ? 180 : 128}px auto 90px`
		}}
	>
		<Header pushLogoLeft={pushLogoLeft} rightBlock={rightBlock} />
		{children}
		{displayFooter && <Footer />}
	</Grid>
);

export default PageWithLogo;
