import { Box, Divider, Typography, styled, useMediaQuery } from "@mui/material";
import DynamicFooter from "components/atoms/DynamicFooter";
import Loader from "components/atoms/Loader";
import usePrefix from "hooks/usePrefix";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { theme } from "theme";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

export const StyledButtonsContainer = styled(Box)`
	display: flex;
	gap: 16px;
`;

const ButtonFooter = ({
	isLoading = false,
	primaryButtonText,
	handlePrimaryButton,
	activeStep = 0,
	disablePrimaryButton = false,
	secondaryButton = false,
	handleSecondaryButton = () => {}
}) => {
	const { t } = useTranslation();

	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const { linkPrefix } = usePrefix();
	return (
		<DynamicFooter>
			{(isDesktop || activeStep != 1) && (
				<Typography variant="textMedium" color="tertiary.main">
					{t("signUpPageRegular.signInText")}
					<Typography
						display="inline"
						variant="linkMedium"
						color="primary.main"
						component={Link}
						to={`${linkPrefix}/signIn`}
					>
						{t("signUpPageRegular.signInAction")}
					</Typography>
				</Typography>
			)}
			{!isDesktop && activeStep != 1 && (
				<Divider
					sx={{
						width: "100%",
						backgroundColor: theme => theme.palette.customShades.light3,
						marginTop: "32px",
						marginBottom: "24px"
					}}
				/>
			)}
			<StyledButtonsContainer width={{ xs: "100%", md: 335 }} justifyContent={{ xs: "center", md: "end" }}>
				{secondaryButton && (
					<SecondaryButton variant="contained" onClick={handleSecondaryButton}>
						{t("buttons.back")}
					</SecondaryButton>
				)}
				<PrimaryButton
					type="submit"
					variant="contained"
					size="large"
					onClick={handlePrimaryButton}
					disabled={disablePrimaryButton}
					maxWidth={isDesktop ? "auto" : "100%"}
				>
					{isLoading ? <Loader color="white" /> : `${primaryButtonText}`}
				</PrimaryButton>
			</StyledButtonsContainer>
		</DynamicFooter>
	);
};

export default ButtonFooter;
