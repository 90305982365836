import { useContext, useEffect, useState } from "react";

import { Grid, Typography, useMediaQuery } from "@mui/material";
import ButtonFooter from "components/molecules/ButtonFooter";
import SelectedListItem from "components/molecules/SelectedListItem";
import UnselectedListItem from "components/molecules/UnselectedListItem";
import FormWrapper from "components/templates/FormWrapper";
import { AppContext } from "contexts/app/AppProvider";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { formTypography } from "theme/formTypography";

import { countries } from "./countries";

import { SimpleFooterWithBackButton } from "../../components/molecules/SimpleFooterWithBackButton";

import { GLOBAL_CONSTANTS } from "../../constants";

import { theme } from "../../theme";

const CountryList = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { setIsRTL } = useContext(AppContext);
	const [selectedCountryId, setSelectedCountryId] = useState("");
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	useEffect(() => {
		i18n.changeLanguage("en");
		setIsRTL(false);
	}, [i18n, setIsRTL]);

	const handlePrimaryButton = (code?: string) => {
		const selectedCountry = countries.find(country => country.countryCode === (code || selectedCountryId));
		const cc = (code || selectedCountryId)?.toLowerCase();
		switch (cc) {
			case "ie":
			case "gb":
				window.location.href = "https://consents.takeda.com/en-gb/signup";
				break;
			case "de":
				window.location.href = GLOBAL_CONSTANTS.GERMANY_REDIR_URL as string;
				break;
			default:
				i18n.changeLanguage(selectedCountry?.defaultLanguage);
				navigate(`/${selectedCountry?.defaultLanguage}-${cc}/signUp`);
		}
	};

	const selectCountryMobile = (code: string) => {
		setSelectedCountryId(code);
		handlePrimaryButton(code);
	};

	return (
		<FormWrapper
			title={t("signUpPageHCP.title")}
			description={t("signUpPageHCP.subtitle")}
			onSubmit={isDesktop ? () => handlePrimaryButton() : () => {}}
			footerBlock={
				isDesktop ? (
					<ButtonFooter
						primaryButtonText={"Continue"}
						handlePrimaryButton={() => handlePrimaryButton()}
						disablePrimaryButton={!selectedCountryId}
						secondaryButton={true}
						handleSecondaryButton={() => navigate(-1)}
					/>
				) : (
					<SimpleFooterWithBackButton />
				)
			}
		>
			<Typography color="customShades.grey1" sx={{ ...formTypography.label }}>
				{t("signUpPageHCP.countrySelect")}
			</Typography>
			<Grid container mt={2}>
				{countries.map(({ countryCode, id, FlagSVG, name }) =>
					countryCode === selectedCountryId ? (
						<SelectedListItem
							key={id}
							SVG={
								<FlagSVG
									width={20}
									height={15}
									style={{
										borderRadius: 2,
										boxShadow: "0px 2px 3px 0px #0000001a"
									}}
								/>
							}
							text={name}
						/>
					) : (
						<UnselectedListItem
							key={id}
							SVG={
								<FlagSVG
									width={20}
									height={15}
									style={{
										borderRadius: 2,
										boxShadow: "0px 2px 3px 0px #0000001a"
									}}
								/>
							}
							text={name}
							onClick={isDesktop ? () => setSelectedCountryId(countryCode) : () => selectCountryMobile(countryCode)}
						/>
					)
				)}
			</Grid>
		</FormWrapper>
	);
};

export default CountryList;
