import React, { useState } from "react";

export interface IAppContext {
	isRTL: boolean;
	setIsRTL: (isRTL: boolean) => void;
	hasHistory?: boolean;
	setHasHistory: (v: boolean) => void;
}

export const AppContext = React.createContext<IAppContext>({
	isRTL: false,
	setIsRTL: () => {},
	setHasHistory: () => {}
});

interface AppProviderProps {
	children: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
	const [isRTL, setIsRTL] = useState(false);
	const [hasHistory, setHasHistory] = useState<boolean | undefined>();

	return <AppContext.Provider value={{ isRTL, setIsRTL, hasHistory, setHasHistory }}>{children}</AppContext.Provider>;
};

export default AppProvider;
