import { useCallback, useContext, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormHelperText } from "@mui/material";
import { requestResetPassword } from "api/features/User/userApi";
import TextFieldInput from "components/molecules/Inputs/TextFieldInput";
import ButtonGroup from "components/organisms/ForgotPassword/ButtonGroup";
import FormWrapper from "components/templates/FormWrapper";
import { AppContext } from "contexts/app/AppProvider";
import usePrefix from "hooks/usePrefix";
import ReCaptcha from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ResetPasswordFormValues, resetPasswordValidationSchema } from "./constant";

const ForgotPasswordForm = () => {
	const { t } = useTranslation();
	const [key, setKey] = useState(0);
	const navigate = useNavigate();
	const { hasHistory } = useContext(AppContext);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errorSummary, setErrorSummary] = useState<string | null>(null);

	const { linkPrefix } = usePrefix();

	const {
		handleSubmit,
		control,
		formState: { isValid, isDirty }
	} = useForm<ResetPasswordFormValues>({
		mode: "onChange",
		resolver: yupResolver(resetPasswordValidationSchema(t)),
		delayError: 1000
	});

	const onSubmit = useCallback(
		async (data: ResetPasswordFormValues) => {
			setIsLoading(true);
			try {
				await requestResetPassword({ username: data.email, ...data });
				navigate(`${linkPrefix}/forgot-password/reset-password-email-sent`);
			} catch (err) {
				const e = err as { response: { message: string } };
				setErrorSummary(e.response.message);
			}
			setIsLoading(false);
			setKey(k => k + 1);
		},
		[linkPrefix, navigate]
	);

	return (
		<FormWrapper
			title={t("forgotPasswordPage.forgotPassword.title")}
			onSubmit={handleSubmit(onSubmit)}
			errorSummary={errorSummary}
			footerBlock={
				<ButtonGroup
					submitTitle={t("forgotPasswordPage.forgotPassword.buttons.reset")}
					backTitle={t("forgotPasswordPage.forgotPassword.buttons.back")}
					isBackButtonEnabled={hasHistory}
					onBack={() => navigate(-1)}
					onSubmit={handleSubmit(onSubmit)}
					dirty={isDirty}
					valid={isValid}
					loading={isLoading}
				/>
			}
		>
			<Controller
				name="email"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<TextFieldInput
						autoFocus
						placeholder={t("forgotPasswordPage.forgotPassword.fields.email.placeholder")}
						variant="outlined"
						value={value}
						onChange={(...p) => {
							onChange(...p);
							if (errorSummary) {
								setErrorSummary(null);
							}
						}}
						label={t("forgotPasswordPage.forgotPassword.fields.email.label")}
						error={!!error}
						helperText={error ? error.message : null}
						type="email"
					/>
				)}
			/>
			<Controller
				control={control}
				name="recaptchaGoogle"
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<>
						<ReCaptcha
							key={key}
							sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
							onChange={onChange}
							value={value}
							verifyCallback={handleSubmit(onSubmit)}
						/>
						{error ? (
							<FormHelperText error sx={{ margin: 0 }}>
								{error.message}
							</FormHelperText>
						) : null}
					</>
				)}
			/>
		</FormWrapper>
	);
};

export default ForgotPasswordForm;
