import { TFunction } from "react-i18next";
import * as yup from "yup";

export interface SignInFormValues {
	email: string;
	password: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const loginValidationSchema = (t: TFunction<"translation", undefined>) =>
	yup.object().shape({
		email: yup
			.string()
			.email(t("signInPage.fields.email.validation.email"))
			.required(t("signInPage.fields.email.validation.required"))
			.typeError(t("signInPage.fields.email.validation.string")),
		password: yup.string().required(t("signInPage.fields.password.validation.required"))
	});
