import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "api/baseApiService";

import { request } from "api/request";

import { UPSProfession } from "models";

import { BaseUpdateDto } from "../../baseApiService";
import { URLS } from "../services.constants";

export class UPSService extends BaseApiService<
	UPSProfession,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	async getProfessions(): Promise<UPSProfession[]> {
		return request("https://profile-api.takeda-digital.de/api/DataSchemaProperties/1/values", "GET") as Promise<
			UPSProfession[]
		>;
	}
}

export const upsService = new UPSService(URLS.UPS);
