export const formTypography = {
	label: {
		fontFamily: "InterVariable",
		fontSize: "13px",
		fontWeight: "700",
		fontStyle: "normal",
		textAlign: "start",
		lineHeight: "16px",
		textTransform: "uppercase"
	},
	placeholder: {
		large: {
			fontFamily: "InterVariable",
			fontSize: "16px",
			fontWeight: "400",
			fontStyle: "normal",
			textAlign: "start"
		},
		medium: {
			fontFamily: "InterVariable",
			fontSize: "14px",
			fontWeight: "400",
			fontStyle: "normal",
			textAlign: "start"
		},
		small: {
			fontFamily: "InterVariable",
			fontSize: "12px",
			fontWeight: "400",
			fontStyle: "normal",
			textAlign: "start"
		}
	},
	inputText: {
		large: {
			fontFamily: "InterVariable",
			fontSize: "16px",
			fontWeight: "500",
			fontStyle: "normal",
			textAlign: "start"
		},
		medium: {
			fontFamily: "InterVariable",
			fontSize: "14px",
			fontWeight: "500",
			fontStyle: "normal",
			textAlign: "start"
		},
		small: {
			fontFamily: "InterVariable",
			fontSize: "12px",
			fontWeight: "500",
			fontStyle: "normal",
			textAlign: "start"
		}
	},
	caption: {
		fontSize: "13px",
		fontWeight: "400",
		fontStyle: "normal"
	},
	option: {
		primary: {
			fontSize: "14px",
			fontWeight: "600",
			fontStyle: "normal",
			lineHeight: "20px"
		},
		alternative: {
			fontSize: "14px",
			fontWeight: "600",
			fontStyle: "normal"
		}
	},
	datePicker: {
		default: {
			fontSize: "15px",
			fontWeight: "500",
			fontStyle: "normal",
			textAlign: "center",
			lineHeight: "24px",
			textTransform: "uppercase"
		},
		weekday: {
			fontSize: "15px",
			fontWeight: "600",
			fontStyle: "normal",
			textAlign: "center",
			lineHeight: "24px"
		}
	}
};
