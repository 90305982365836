import { FC } from "react";

import { List, ListItem as MuiListItem, Typography } from "@mui/material";

import { Profile } from "models";
import { useTranslation } from "react-i18next";

import { IOption } from "../models";

const ListItem: FC<{ title: string; value?: string }> = ({ title, value }) => (
	<MuiListItem
		sx={t => ({
			display: "flex",
			justifyContent: "space-between",
			borderBottom: `1px solid ${t.palette.customShades.light3}`,
			padding: "24px 0"
		})}
	>
		<Typography variant="h6Headline" color="common.black">
			{title}
		</Typography>
		<Typography
			variant="textMedium"
			color="common.black"
			align="right"
			sx={{ maxWidth: "70%", wordWrap: "break-word" }}
		>
			{value}
		</Typography>
	</MuiListItem>
);

interface Props {
	profile: Profile;
	countryOptions: IOption[];
	languageOptions: IOption[];
}

const UserData: FC<Props> = ({ profile, countryOptions, languageOptions }) => {
	const { t } = useTranslation();

	return (
		<List sx={{ marginTop: { xs: 3, md: 6 } }}>
			<ListItem title={t("account.userData.email")} value={profile.email} />
			<ListItem
				title={t("account.userData.country")}
				value={countryOptions?.find(c => c.value === profile?.countryCode?.toUpperCase())?.label}
			/>
			<ListItem
				title={t("account.userData.language")}
				value={languageOptions?.find(l => l.value === profile?.preferredLanguage?.toLowerCase())?.label}
			/>
		</List>
	);
};

export default UserData;
