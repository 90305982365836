import isEmpty from "lodash/isEmpty";

import { clearJwt, getJwt, setJwt } from "./jwt";

import { GLOBAL_CONSTANTS } from "../constants";

import { getTracingId } from "../utils/getUniqueValue";
const BASE_URL = GLOBAL_CONSTANTS.BACKEND_API;

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
export interface IHeaderOptions {
	[fieldName: string]: string;
}
const parseStatus = <T>(res: Response, data: Promise<T>): Promise<T> => {
	return new Promise((resolve, reject) => {
		if (res.ok) {
			if (res.headers) {
				const headerEntries = res.headers.entries();
				let authHeader: string | null = null;
				for (const pair of headerEntries) {
					if (pair[0] === "authorization") {
						authHeader = pair[1] as string;
					}
				}
				if (typeof authHeader === "string" && authHeader.length) {
					const authHeaderData = authHeader.split(" ");
					if (authHeaderData[0] === "Bearer") {
						setJwt(authHeaderData[1], authHeaderData[2]);
					}
				}
			}
			data.then(response => resolve(response));
		} else {
			if (res.status === 401) {
				clearJwt();
				window.location.pathname = "/signIn";
			}

			data.then(response => reject({ code: res.status, response }));
		}
	});
};

export const request = async function <T>(
	url: string,
	method: RequestMethod = "GET",
	body?: Record<string, unknown>,
	options?: RequestInit,
	skipToken = false,
	emptyResponse = false,
	additionalHeader?: Record<string, unknown>
): Promise<T | null> {
	const reqOptions: RequestInit = {
		method,
		headers: requestHeaders(skipToken, additionalHeader as IHeaderOptions),
		body: method !== "GET" ? (body instanceof File ? body : JSON.stringify(body)) : null,
		...options
	};

	// If provided url is valid full url, like https://google.com we use it,
	// instead of appending it to BASE_URL
	let useBaseUrl = true;
	try {
		const validUrl = new URL(url);
		if (validUrl) useBaseUrl = false;
	} catch {}
	const finalUrl = `${useBaseUrl ? BASE_URL : ""}${url}`;
	try {
		const res = await fetch(`${finalUrl}`, reqOptions);
		return await parseStatus(res, emptyResponse ? new Promise(resolve => resolve(null)) : res.json());
	} catch (e) {
		throw e;
	}
};

export const requestHeaders = (skipToken = false, additionalHeader: IHeaderOptions = {}): Headers => {
	const headers = new Headers();
	headers.append("Content-Type", "application/json");
	headers.append("Accept", "application/json");
	headers.append("tracing-number", getTracingId());

	if (!isEmpty(additionalHeader)) {
		for (const key in additionalHeader) {
			headers.append(`${key}`, `${additionalHeader[key]}`);
		}
	}
	if (!skipToken) {
		const token = getJwt();
		headers.append("Authorization", `Bearer ${token}`);
	}

	return headers;
};
