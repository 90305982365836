import { FC } from "react";

import { ButtonProps, Button as MuiButton } from "@mui/material";

const Button: FC<ButtonProps> = ({ children, ...rest }) => (
	<MuiButton disableElevation={true} {...rest}>
		{children}
	</MuiButton>
);

export default Button;
