import { useContext, useEffect, useState } from "react";

import { Box, Grid, Typography, styled, useMediaQuery } from "@mui/material";
import { resendEmail } from "api/features/Auth/authApi";
import PageWithLogo from "components/templates/PageWithLogo";
import { AppContext } from "contexts/app/AppProvider";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";

import { theme } from "theme";

import animationData from "assets/animations/email_sent.json";
import animationDataCropped from "assets/animations/email_sent_cropped.json";

const StyledContainer = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-content: center;
	align-items: center;
`;
const defaultOptions = {
	loop: false,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

const defaultOptionsForCropped = {
	loop: false,
	autoplay: true,
	animationData: animationDataCropped,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice"
	}
};

const EmailVerification = ({ email }) => {
	const { t } = useTranslation();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const [animationCompleted, setAnimationCompleted] = useState(false);
	const [resentCode, setResentCode] = useState<boolean>(true);
	const { isRTL } = useContext(AppContext);
	const [counter, setCounter] = useState<number>(60);

	useEffect(() => {
		if (resentCode) {
			setTimeout(() => {
				if (counter > 0) {
					setCounter(counter - 1);
				} else {
					setResentCode(false);
					setCounter(60);
				}
			}, 1000);
		}
	}, [resentCode, counter]);

	const resendCode = async email => {
		setResentCode(true);
		await resendEmail(email);
	};

	return (
		<PageWithLogo>
			<StyledContainer dir={isRTL ? "rtl" : "ltr"}>
				{!animationCompleted ? (
					<Lottie
						options={defaultOptions}
						height={240}
						width={252}
						eventListeners={[
							{
								eventName: "complete",
								callback: () => setAnimationCompleted(true)
							}
						]}
					/>
				) : (
					<Lottie options={defaultOptionsForCropped} height={240} width={252} />
				)}
				<Grid
					container
					spacing={1}
					paddingX={{ xs: 3, md: 0 }}
					direction="column"
					alignItems="center"
					justifyContent="center"
					maxWidth={640}
				>
					<Grid item xs={12} display="flex" mt={4}>
						<Typography variant={isDesktop ? "h1Headline" : "h4Headline"} color="tertiary.main" align="center">
							{t("emailVerification.heading")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="textMedium" color="customShades.grey1" mt={3}>
							{t("emailVerification.subtitle")}{" "}
						</Typography>
					</Grid>
					<Grid item xs={12} mt={0.5}>
						<Typography variant="linkMedium" color="tertiary.main">
							{email}
						</Typography>
					</Grid>
					<Grid item xs={12} display="flex">
						<Typography variant="textMedium" color="customShades.grey1" mt={0.5} align="center">
							{t("emailVerification.help")}{" "}
						</Typography>
					</Grid>
					<Grid xs={12} display="flex">
						<Typography variant="textMedium" color="customShades.grey1" align="center">
							{t("emailVerification.moreInfo")}{" "}
						</Typography>
					</Grid>
					<Grid item xs={2} display="flex" mt={3}>
						<Typography variant="textMedium" color="customShades.grey1" align="center">
							{resentCode ? `Resend email in ${counter} sec` : "Didn’t get an email?"}
							<Typography
								onClick={resentCode ? () => {} : () => resendCode(email)}
								sx={{ cursor: resentCode ? "not-allowed" : "pointer", opacity: resentCode ? 0.4 : 1 }}
								variant="linkMedium"
								color="primary.main"
								marginLeft={1}
							>
								Resend email
							</Typography>
						</Typography>
					</Grid>
				</Grid>
			</StyledContainer>
		</PageWithLogo>
	);
};

export default EmailVerification;
