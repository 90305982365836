import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "api/baseApiService";
import { request } from "api/request";
import { Form } from "models/form.model";

import { URLS } from "../services.constants";

export class FormsService extends BaseApiService<
	Form,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
	getFormsByCountry(countryId: string): Promise<Form> {
		return request(`${this.baseUrl}/${countryId}`, "GET") as Promise<Form>;
	}
}

export const formsService = new FormsService(URLS.FORMS);
