import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "api/baseApiService";

import { request } from "api/request";
import { User } from "models";

import {
	CRMUserRequestDto,
	CRMUserResponseDto,
	CheckAPIDto,
	CheckVerifyAPIDto,
	UserSignUpDto,
	UserSignUpResponseDto
} from "./dto";

import { BaseUpdateDto } from "../../baseApiService";
import { URLS } from "../services.constants";

export class AuthService extends BaseApiService<
	User,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	signUp(data: UserSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/register`,
			"POST",
			data as unknown as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	checkUserExists(username: string): Promise<CheckAPIDto> {
		return request(
			`${this.baseUrl}/check_user_exists`,
			"POST",
			this.emptyStringToNull({ username }) as Record<string, unknown>
		) as Promise<CheckAPIDto>;
	}

	checkCRMUserExists(data: CRMUserRequestDto): Promise<CRMUserResponseDto> {
		return request(
			`${this.baseUrl}/check_crm_user_exists`,
			"POST",
			data as unknown as Record<string, unknown>
		) as Promise<CRMUserResponseDto>;
	}

	verifyEmail(recoveryToken: string): Promise<CheckVerifyAPIDto> {
		return request(
			`${this.baseUrl}/confirm_registration`,
			"POST",
			this.emptyStringToNull({ recoveryToken }) as Record<string, unknown>
		) as Promise<CheckVerifyAPIDto>;
	}

	resendEmail(username: string): Promise<{ success: string }> {
		return request(`${this.baseUrl}/resend_confirmation`, "POST", { username }) as Promise<{ success: string }>;
	}
}
export const authService = new AuthService(URLS.AUTH);
