import { Checkbox as MuiCheckbox } from "@mui/material";

const Checkbox = props => (
	<MuiCheckbox
		color="primary"
		disableRipple={true}
		{...props}
		sx={{
			"&.MuiCheckbox-root": {
				"&:hover": {
					backgroundColor: "transparent ",
					transform: "none"
				}
			}
		}}
	/>
);

export default Checkbox;
