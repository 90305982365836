import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "api/baseApiService";

import { request } from "api/request";

import { MuleSoftUser, User } from "models";

import { ChangeUserResponseModel, ConnectUser, ResetPasswordResult } from "./dto";

import { BaseUpdateDto } from "../../baseApiService";
import { URLS } from "../services.constants";

export class UserService extends BaseApiService<
	User,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getUserData(): Promise<MuleSoftUser> {
		return request(`${this.baseUrl}/me`, "GET") as Promise<MuleSoftUser>;
	}

	changeUser(data: User): Promise<ChangeUserResponseModel> {
		return request(`${this.baseUrl}`, "PUT", data) as Promise<ChangeUserResponseModel>;
	}

	changePassword(oldPassword: string, newPassword: string): Promise<{ success: boolean }> {
		return request(`${this.baseUrl}/change_password`, "PUT", { oldPassword, newPassword }) as Promise<{
			success: boolean;
		}>;
	}

	requestResetPassword(username: string, recaptchaGoogle: string): Promise<ResetPasswordResult> {
		return request(`${this.baseUrl}/reset_password`, "POST", {
			username,
			recaptchaGoogle
		}) as Promise<ResetPasswordResult>;
	}

	resetPassword(recoveryToken: string, newPassword: string): Promise<ResetPasswordResult> {
		return request(`${this.baseUrl}/reset_password`, "PUT", {
			recoveryToken,
			newPassword
		}) as Promise<ResetPasswordResult>;
	}
	verifyToken(token: string): Promise<ConnectUser> {
		return request(`${this.baseUrl}/invitation/${encodeURIComponent(token)}`, "GET") as Promise<ConnectUser>;
	}

	checkTokenAlive(recoveryToken: string): Promise<{ result: { isTakedaAccount: boolean } | null }> {
		return request(`${this.baseUrl}/checkToken/${recoveryToken}`) as Promise<{
			result: { isTakedaAccount: boolean } | null;
		}>;
	}
}
export const userService = new UserService(URLS.USERS);
