import SmartRedirect from "components/organisms/SmartRedirect";
import AppProvider from "contexts/app";
import CountryList from "pages/CountryList";
import EmailActivated from "pages/EmailActivated";
import { Error403, Error404 } from "pages/Errors";
import ExternalAuth from "pages/ExternalAuth";
import FAQ from "pages/FAQ";
import ForgotPassword from "pages/ForgotPassword";
import { PrivacyPolicy, TermsOfUse } from "pages/Legal";
import Profile from "pages/Profile";
import SignIn from "pages/SignIn";
import { SignOut } from "pages/SignOut";
import SignUp from "pages/SignUp";
import SignUpConnect from "pages/SignUpConnect";
import SignUpHCP from "pages/SignUpHCP";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const AppRoutes = () => (
	<BrowserRouter>
		<AppProvider>
			<SmartRedirect>
				<Routes>
					<Route path="/externalAuth" element={<ExternalAuth />} />
					<Route path="/signIn" element={<SignIn />} />
					<Route path="/:defaultLanguage-:countryId/signIn" element={<SignIn />} />
					<Route path="/self-registration" element={<SignUp />} />
					<Route path="/:defaultLanguage-:countryId/self-registration" element={<SignUp />} />
					<Route path="/:defaultLanguage-:countryId/forgot-password/*" element={<ForgotPassword />} />
					<Route path="/forgot-password/*" element={<ForgotPassword />} />
					<Route path="/profile/*" element={<Profile />} />
					<Route path="/:defaultLanguage-:countryId/emailActivate/:code" element={<EmailActivated />} />
					<Route path="/emailActivate/:code" element={<EmailActivated />} />
					<Route path="/countryList" element={<CountryList />} />
					<Route path="/signup" element={<CountryList />} />
					<Route path="/:defaultLanguage-:countryId/signUp" element={<SignUpHCP />} />
					<Route path="/:defaultLanguage-:countryId/invitation-signup/:token" element={<SignUpConnect />} />
					<Route path="/:defaultLanguage-:countryId/connect-invitation/:token" element={<SignUpConnect />} />
					<Route path="/:defaultLanguage-:countryId/connect-invitation" element={<SignUpHCP />} />
					<Route path="/:defaultLanguage-:countryId/terms-and-conditions" element={<TermsOfUse />} />
					<Route path="terms-and-conditions" element={<TermsOfUse />} />
					<Route path="/:defaultLanguage-:countryId/privacy-notice" element={<PrivacyPolicy />} />
					<Route path="/privacy-notice" element={<PrivacyPolicy />} />
					<Route path="/faq" element={<FAQ />} />
					<Route path="/:defaultLanguage-:countryId/faq" element={<FAQ />} />
					<Route path="/" element={<SignUp />} />
					<Route path="/login" element={<SignUp />} />
					<Route path="/signOut/*" element={<SignOut />} />
					<Route path="/:defaultLanguage-:countryId/access-denied" element={<Error403 />} />
					<Route path="/access-denied" element={<Error403 />} />
					<Route path="/not-found" element={<Error404 />} />
					<Route path="/:defaultLanguage-:countryId/*" element={<Error404 />} />
					<Route path="*" element={<Error404 />} />
				</Routes>
			</SmartRedirect>
		</AppProvider>
	</BrowserRouter>
);

export default AppRoutes;
