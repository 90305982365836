import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Grid } from "@mui/material";
import { changePassword } from "api/features/User/userApi";
import PasswordInput from "components/molecules/Inputs/PasswordInput";
import PasswordRegexValidator from "components/molecules/PasswordRegexValidator";
import { isEqual } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { isTakedaAccount } from "utils/checkTakedaAccount";

import { ChangeFormWrapper, ChangePasswordSuccessScreen } from "../components";
import { ChangePasswordFormProps, changePasswordValidationSchema } from "../constants";

interface Props {
	isMobile: boolean;
	step?: number;
	changeStep: Dispatch<SetStateAction<number | undefined>>;
	userName?: string;
}

const ChangePasswordForm: FC<Props> = ({ isMobile, changeStep, step, userName }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [submitted, setSubmitted] = useState(false);
	const [apiError, setApiError] = useState<string | null>(null);

	const specialEmail = useMemo(() => isTakedaAccount(userName), [userName]);

	const {
		control,
		handleSubmit,
		reset,
		trigger,
		watch,
		formState: { isValid, isDirty, isSubmitting }
	} = useForm<ChangePasswordFormProps>({
		mode: "onChange",
		resolver: yupResolver(changePasswordValidationSchema(t, specialEmail)),
		defaultValues: {
			oldPassword: "",
			newPassword: "",
			confirmPassword: ""
		}
	});

	const onSubmit = async (data: ChangePasswordFormProps) => {
		try {
			await changePassword(data);
			reset();
			setSubmitted(true);
		} catch (err) {
			const e = err as { response: { message: string } };
			setApiError(e.response.message);
		}
	};

	const [newPassword, confirmPassword] = watch(["newPassword", "confirmPassword"]);

	const matchPassword = useMemo(() => isEqual(newPassword, confirmPassword), [newPassword, confirmPassword]);

	if (!apiError && submitted) {
		return (
			<ChangePasswordSuccessScreen
				onButtonClick={() => {
					changeStep(1);
					navigate("/profile");
				}}
			/>
		);
	}

	return (
		<ChangeFormWrapper
			title={t("changePasswordPage.title")}
			onSubmit={handleSubmit(onSubmit)}
			apiError={apiError}
			changeStep={changeStep}
			isDirty={isDirty}
			isMobile={isMobile}
			isSubmitting={isSubmitting}
			isValid={isValid}
			step={step}
			submitButtonTitle={t("changePasswordPage.buttons.change")}
			showBackButton
		>
			<Grid item xs={12}>
				<Controller
					name="oldPassword"
					control={control}
					render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
						<PasswordInput
							placeholder={t("changePasswordPage.fields.oldPassword.placeholder")}
							label={t("changePasswordPage.fields.oldPassword.label")}
							error={!!error}
							errorMessage={error ? error.message : null}
							onChange={(...p) => {
								onChange(...p);
								if (apiError) {
									setApiError(null);
								}
							}}
							{...rest}
						/>
					)}
				/>
				<Controller
					name="newPassword"
					control={control}
					render={({ field: { onChange, onBlur, ...rest }, fieldState: { error } }) => (
						<PasswordInput
							placeholder={t("changePasswordPage.fields.newPassword.placeholder")}
							label={t("changePasswordPage.fields.newPassword.label")}
							error={!!error}
							errorMessage={error ? error.message : null}
							onBlur={() => {
								trigger("confirmPassword");
								onBlur();
							}}
							onChange={(...p) => {
								onChange(...p);
								if (apiError) {
									setApiError(null);
								}
							}}
							{...rest}
						/>
					)}
				/>
				<Controller
					name="confirmPassword"
					control={control}
					render={({ field: { onChange, ...rest }, fieldState: { error, invalid, isDirty } }) => (
						<PasswordInput
							placeholder={t("changePasswordPage.fields.confirmPassword.placeholder")}
							label={t("changePasswordPage.fields.confirmPassword.label")}
							error={!!error}
							errorMessage={error ? error.message : null}
							onChange={(...p) => {
								onChange(...p);
								if (apiError) {
									setApiError(null);
								}
							}}
							{...rest}
							validMessage={
								!invalid && isDirty ? t("changePasswordPage.fields.confirmPassword.validation.matched") : undefined
							}
						/>
					)}
				/>
				{!!newPassword.length && (
					<PasswordRegexValidator
						password={newPassword}
						isTakedaEmail={specialEmail}
						additionalPasswordRules={[
							{
								rule: matchPassword,
								description: t("passwordRules.shouldMatch")
							}
						]}
					/>
				)}
			</Grid>
		</ChangeFormWrapper>
	);
};

export default ChangePasswordForm;
