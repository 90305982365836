import { FC, ReactNode, useState } from "react";

import { Box, List, ListItem as MuiListItem, Typography } from "@mui/material";

import { signOutRequest } from "api/features/Auth/authApi";
import { ArrowRight } from "assets";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import SecondaryButton from "components/molecules/Buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ConsentModal from "./ConsentModal";

const ListItem: FC<{ children: ReactNode; onClick: () => void }> = ({ children, onClick }) => (
	<MuiListItem
		sx={{ height: 56, padding: "14px 0", cursor: "pointer", display: "flex", justifyContent: "space-between" }}
		onClick={onClick}
	>
		<Typography variant="h6Headline" color="common.black">
			{children}
		</Typography>
		<ArrowRight color={"red"} />
	</MuiListItem>
);

const ButtonBlock: FC<{
	isDesktop: boolean;
	onEditProfile: () => void;
	onChangePassword: () => void;
	onConsent: () => void;
}> = ({ isDesktop, onEditProfile, onChangePassword, onConsent }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [consentModal, setConsentModal] = useState<boolean>(false);

	const MobileListLinks = () => (
		<List>
			<ListItem onClick={onEditProfile}>{t("account.buttons.editProfile")}</ListItem>
			<ListItem onClick={onConsent}>{t("account.buttons.manageConsent")}</ListItem>
			<ListItem onClick={onChangePassword}>{t("account.buttons.changePassword")}</ListItem>
		</List>
	);

	return (
		<>
			<Box>
				{isDesktop ? (
					<>
						<SecondaryButton
							marginTop={16}
							fullWidth
							variant="contained"
							size="large"
							onClick={() => setConsentModal(true)}
						>
							{t("account.buttons.manageConsent")}
						</SecondaryButton>
						<SecondaryButton
							marginTop={16}
							fullWidth
							variant="contained"
							size="large"
							onClick={() => navigate("/profile/change-password")}
						>
							{t("account.buttons.changePassword")}
						</SecondaryButton>
					</>
				) : (
					<MobileListLinks />
				)}

				<PrimaryButton
					fullWidth
					maxWidth="100%"
					marginTop={16}
					variant="contained"
					size="large"
					onClick={() => signOutRequest()}
				>
					{t("account.buttons.signOut")}
				</PrimaryButton>
			</Box>
			{consentModal && <ConsentModal open={consentModal} setModal={setConsentModal} />}
		</>
	);
};

export default ButtonBlock;
