import "@fontsource/inter/variable-full.css";
import { colors, createTheme, experimental_sx as sx } from "@mui/material";

// declare module "@mui/material/styles/createPalette" {}

declare module "@mui/material/styles" {
	interface CustomShadesColorOptions {
		grey1?: string;
		grey2?: string;
		grey3?: string;
		dark1?: string;
		dark2?: string;
		dark3?: string;
		dark4?: string;
		dark5?: string;
		light1?: string;
		light2?: string;
		light3?: string;
		light4?: string;
		light5?: string;
	}
	interface CustomShadesPaletteColor {
		grey1?: string;
		grey2?: string;
		grey3?: string;
		dark1?: string;
		dark2?: string;
		dark3?: string;
		dark4?: string;
		dark5?: string;
		light1?: string;
		light2?: string;
		light3?: string;
		light4?: string;
		light5?: string;
	}
	interface PaletteOptions {
		customShades?: CustomShadesColorOptions;
		tertiary?: PaletteOptions["primary"];
	}
	interface Palette {
		customShades: CustomShadesPaletteColor;
		tertiary: Palette["primary"];
	}
	interface TypographyVariants {
		h1Headline: React.CSSProperties;
		h2Headline: React.CSSProperties;
		h3Headline: React.CSSProperties;
		h4Headline: React.CSSProperties;
		h5Headline: React.CSSProperties;
		h6Headline: React.CSSProperties;
		linkLead: React.CSSProperties;
		linkMedium: React.CSSProperties;
		linkSmall: React.CSSProperties;
		textLead: React.CSSProperties;
		textMedium: React.CSSProperties;
		textSmall: React.CSSProperties;
		tag: React.CSSProperties;
		tab: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		h1Headline: React.CSSProperties;
		h2Headline: React.CSSProperties;
		h3Headline: React.CSSProperties;
		h4Headline: React.CSSProperties;
		h5Headline: React.CSSProperties;
		h6Headline: React.CSSProperties;
		linkLead: React.CSSProperties;
		linkMedium: React.CSSProperties;
		linkSmall: React.CSSProperties;
		textLead: React.CSSProperties;
		textMedium: React.CSSProperties;
		textSmall: React.CSSProperties;
		tag: React.CSSProperties;
		tab: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		h1Headline: true;
		h2Headline: true;
		h3Headline: true;
		h4Headline: true;
		h5Headline: true;
		h6Headline: true;
		linkLead: true;
		linkMedium: true;
		linkSmall: true;
		textLead: true;
		textMedium: true;
		textSmall: true;
		tag: true;
		tab: true;
	}
}

const palette = {
	common: {
		black: "#000000",
		white: "#ffffff"
	},
	type: "light",
	primary: {
		main: "#E1242A",
		light: "#F78C85",
		dark: "#BD120A"
	},
	secondary: {
		main: "#4C9BCF",
		light: "#F35F92",
		dark: "#CA207D"
	},
	tertiary: {
		main: "#000000",
		light: "#222B45",
		dark: "#151A30"
	},

	info: {
		main: "#51B1BF"
	},
	success: {
		main: "#ABB436"
	},
	warning: {
		main: "#EBA800"
	},
	error: {
		main: "#EA5532"
	},
	background: {
		default: colors.common.white,
		paper: colors.common.white
	},
	text: {
		primary: "#E1242A",
		secondary: "#4C9BCF"
	},

	customShades: {
		grey1: "#A1A4AC",
		grey2: "#EDF2F4",
		grey3: "#34373F",
		dark1: "#2E3A59",
		dark2: "#222B45",
		dark3: "#192038",
		dark4: "#151A30",
		dark5: "#101426",
		light1: "#FFFFFF",
		light2: "#F7F9FC",
		light3: "#EDF1F7",
		light4: "#E4E9F2",
		light5: "#C5CEE0"
	}
};

export const theme = createTheme({
	palette,
	typography: {
		fontFamily: ["Open Sans", "InterVariable", "sans-serif"].join(","),
		h1Headline: {
			fontSize: "36px",
			fontWeight: "700",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "48px"
		},
		h2Headline: {
			fontSize: "32px",
			fontWeight: "700",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "40px"
		},
		h3Headline: {
			fontSize: "28px",
			fontWeight: "700",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "36px"
		},
		h4Headline: {
			fontSize: "24px",
			fontWeight: "700",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "32px"
		},
		h5Headline: {
			fontSize: "20px",
			fontWeight: "700",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "28px"
		},
		h6Headline: {
			fontSize: "16px",
			fontWeight: "700",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "24px"
		},
		linkLead: {
			fontSize: "20px",
			fontWeight: "600",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "32px"
		},
		linkMedium: {
			fontSize: "16px",
			fontWeight: "600",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "28px"
		},
		linkSmall: {
			fontSize: "13px",
			fontWeight: "600",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "18px"
		},
		textLead: {
			fontSize: "20px",
			fontWeight: "400",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "32px"
		},
		textMedium: {
			fontSize: "16px",
			fontWeight: "400",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "28px"
		},
		textSmall: {
			fontSize: "13px",
			fontWeight: "400",
			fontStyle: "normal",
			textAlign: "start",
			lineHeight: "18px"
		},
		tag: {
			fontFamily: "InterVariable",
			fontSize: "12px",
			fontWeight: "600",
			fontStyle: "normal",
			textAlign: "center",
			lineHeight: "16px"
		},
		tab: {
			fontFamily: "InterVariable",
			fontSize: "14px",
			fontWeight: "700",
			fontStyle: "normal",
			textAlign: "center",
			lineHeight: "16px",
			textTransform: "uppercase"
		}
	},
	spacing: 8,
	components: {
		MuiInputBase: {
			styleOverrides: {
				input: {
					background: "#F7F9FC",
					":hover": {
						background: "#EDF1F7"
					},
					":focus": {
						background: "#FFFF"
					}
				}
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					transform: "none"
				}
			}
		},
		MuiCheckbox: {
			styleOverrides: {
				root: [
					sx({
						scale: { xs: "1.6 !important", md: "1 !important" }
					})
				]
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					color: colors.common.white,
					background: "#101426",
					fontSize: "13px",
					fontWeight: "400",
					fontStyle: "normal",
					textAlign: "start",
					lineHeight: "18px"
				},
				arrow: {
					color: "#101426"
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					textAlign: "start"
				}
			}
		}
	}
});
