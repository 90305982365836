import { Box, Grid, Typography } from "@mui/material";

import { StyledContainer } from "./style";

interface UnselectedListItemProps {
	SVG: React.SVGProps<SVGElement>;
	text: string;
	onClick: () => void;
}

const UnselectedListItem = ({ SVG, text, onClick }: UnselectedListItemProps) => {
	return (
		<>
			<StyledContainer item xs={12} md={5} onClick={onClick}>
				<Box display="flex" gap={2} alignItems="center">
					<>
						{SVG}
						<Typography variant="linkMedium" color="tertiary.main">
							{text}
						</Typography>
					</>
				</Box>
			</StyledContainer>
			<Grid item xs={0} md={1} />
		</>
	);
};

export default UnselectedListItem;
