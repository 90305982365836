import { FC, ReactNode, useCallback, useContext, useEffect, useMemo } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled as MuiStyled, useTheme } from "@mui/material/styles";

import AlertWithTitle from "components/molecules/AlertWithTitle";
import PageWithLogo from "components/templates/PageWithLogo";
import PageWithSideInfo from "components/templates/PageWithSideInfo";
import { AppContext } from "contexts/app/AppProvider";
import usePrefix from "hooks/usePrefix";
import { Link } from "react-router-dom";

import styled from "styled-components";

import Logo from "assets/images/takeda-logo.svg";

const FormContainer = MuiStyled(Box)`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
`;

const StyledButtonsContainer = styled(Box)`
	display: flex;
	border-top: ${({ theme }) => `1px solid ${theme.palette.customShades.light3}`};
	justify-content: end;
	width: 100%;
	align-items: center;
	padding: 16px 40px;
`;

const Greeting: FC<{ title: string; description: string | undefined }> = ({ title, description }) => (
	<>
		<Typography variant="h1Headline" color="tertiary.main" mb={description ? 1 : 5}>
			{title}
		</Typography>
		{description && (
			<Typography variant="textMedium" color="customShades.grey1" mb={5}>
				{description}
			</Typography>
		)}
	</>
);

const FooterBlock = ({ children }) => <StyledButtonsContainer>{children}</StyledButtonsContainer>;

const Wrapper: FC<{ isDesktop: boolean; children: ReactNode; footerBlock: ReactNode }> = ({
	isDesktop,
	footerBlock,
	children
}) =>
	useMemo(
		() =>
			isDesktop ? (
				<PageWithSideInfo containerFooter={<FooterBlock>{footerBlock}</FooterBlock>}>{children}</PageWithSideInfo>
			) : (
				<PageWithLogo>{children}</PageWithLogo>
			),
		[isDesktop, footerBlock, children]
	);
interface Props {
	title: string;
	description?: string;
	children: ReactNode;
	footerBlock: ReactNode;
	onSubmit: () => void;
	errorSummary?: string | null;
}

const FormWrapper: FC<Props> = ({ children, footerBlock, onSubmit, title, description, errorSummary }) => {
	const theme = useTheme();
	const { isRTL } = useContext(AppContext);
	const { linkPrefix } = usePrefix();

	const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

	const keyPress = useCallback(
		(e: KeyboardEvent) => {
			if (e.code === "Enter") {
				onSubmit();
			}
		},
		[onSubmit]
	);

	useEffect(() => {
		window.addEventListener("keydown", keyPress);
		return () => {
			window.removeEventListener("keydown", keyPress);
		};
	}, [keyPress]);

	return (
		<Wrapper isDesktop={isDesktop} footerBlock={footerBlock}>
			<Form onSubmit={onSubmit} autocomplete={false}>
				<Box padding={{ xs: "0px 24px", md: 0 }}>
					{isDesktop && (
						<Link to={`${linkPrefix}/signIn`} style={{ width: 150, display: "block" }}>
							<Box component="img" src={Logo} />
						</Link>
					)}
					<FormContainer dir={isRTL ? "rtl" : "ltr"}>
						<Greeting title={title} description={description} />
						{errorSummary && <AlertWithTitle margin="0 0 32px 0">{errorSummary}</AlertWithTitle>}
						<Box display="flex" flexDirection="column">
							{children}
						</Box>
						{!isDesktop && footerBlock}
					</FormContainer>
				</Box>
			</Form>
		</Wrapper>
	);
};

export default FormWrapper;
