export const GLOBAL_CONSTANTS = {
	BACKEND_API: process.env.REACT_APP_BACKEND_API,
	MAIL_ENCRYPTION_LINK_KEY: process.env.REACT_APP_MAIL_ENCRYPTION_LINK_SECRET_KEY,
	CONTACT_US_EMAIL: process.env.REACT_APP_CONTACT_US_EMAIL,
	TERMS_AND_CONDITIONS_LINK: process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK,
	PRIVACY_POLICY_LINK: process.env.REACT_APP_PRIVACY_POLICY_LINK,
	COOKIE_USE_LINK: process.env.REACT_APP_COOKIE_USE_LINK,
	LOCAL_STORAGE_PREFIX: process.env.REACT_APP_LOCAL_STORAGE_PREFIX,
	JOB_SECRET: process.env.REACT_APP_JOB_SECRET,
	OKTA_URL: process.env.REACT_APP_OKTA_URL,
	OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER,
	OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
	OKTA_REDIRECT_URI: process.env.REACT_APP_OKTA_REDIRECT_URI,
	OKTA_PKCE: !!process.env.REACT_APP_OKTA_PKCE,
	OKTA_RESPONSE_MODE: process.env.REACT_APP_OKTA_RESPONSE_MODE,
	APPLE_STORE_URL: process.env.REACT_APP_APPLE_STORE_URL,
	CONSENT_URL: process.env.REACT_APP_CONSENT_URL,
	LOG_SECRET: process.env.REACT_APP_ERROR_LOG_SECRET,
	GERMANY_REDIR_URL: process.env.REACT_APP_GERMANY_REDIR_URL
};
