import { FC } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";
import { SuccessAnimationData } from "assets";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { theme } from "theme";

const ChangePasswordSuccessScreen: FC<{ onButtonClick: () => void }> = ({ onButtonClick }) => {
	const { t } = useTranslation();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	return (
		<Box
			maxWidth={640}
			alignSelf="center"
			display="flex"
			flexDirection="column"
			justifyContent="center"
			margin="auto"
			alignItems={"center"}
			padding={3}
			height="100%"
			width="100%"
		>
			<Lottie
				options={{
					loop: false,
					autoplay: true,
					animationData: SuccessAnimationData,
					rendererSettings: {
						preserveAspectRatio: "xMidYMid slice"
					}
				}}
				height={212}
				width={217.8}
			/>
			<Typography variant={isDesktop ? "h1Headline" : "h4Headline"} color="tertiary.main" align="center">
				{t("changePasswordPage.passwordChanged.title")}
			</Typography>
			<Typography variant="textMedium" color="customShades.grey1" mt={3} align="center">
				{t("changePasswordPage.passwordChanged.description")}
			</Typography>
			<PrimaryButton variant="contained" type="submit" marginTop={40} onClick={onButtonClick}>
				{t("changePasswordPage.passwordChanged.buttonTitle")}
			</PrimaryButton>
		</Box>
	);
};

export default ChangePasswordSuccessScreen;
