import { recordLog } from "api/features/Log/logApi";
import pino from "pino";

const send = async (level, logEvent) => {
	await recordLog({ level, logEvent });
};

const logger = pino({
	browser: {
		serialize: true,
		asObject: true,
		transmit: {
			send
		}
	}
});

export default logger;
