import { Box, Typography } from "@mui/material";
import InfoPageWrapper from "components/templates/InfoPageWrapper";
import { useTranslation } from "react-i18next";

const FAQ = () => {
	const { t } = useTranslation();
	return (
		<InfoPageWrapper>
			<Typography variant="h1Headline" color="common.black" align="center" component="h1">
				{t("faqPage.title")}
			</Typography>
			<Box>
				<Typography variant="h6Headline" color="common.black" component="h2">
					{t("faqPage.q1")}
				</Typography>
				<Typography variant="textMedium" color="common.black">
					{t("faqPage.a1")}
				</Typography>
			</Box>
			<Box>
				<Typography variant="h6Headline" color="common.black" component="h2">
					{t("faqPage.q2")}
				</Typography>
				<Typography variant="textMedium" color="common.black">
					{t("faqPage.a2")}
				</Typography>
			</Box>
			<Box>
				<Typography variant="h6Headline" color="common.black" component="h2">
					{t("faqPage.q3")}
				</Typography>
				<Typography variant="textMedium" color="common.black">
					{t("faqPage.a3")}
				</Typography>
			</Box>
			<Box>
				<Typography variant="h6Headline" color="common.black" component="h2">
					{t("faqPage.q4")}
				</Typography>
				<Typography variant="textMedium" color="common.black">
					{t("faqPage.a4")}
				</Typography>
			</Box>
			<Box>
				<Typography variant="h6Headline" color="common.black" component="h2">
					{t("faqPage.q5")}
				</Typography>
				<Typography variant="textMedium" color="common.black">
					{t("faqPage.a5")}
				</Typography>
			</Box>
		</InfoPageWrapper>
	);
};

export default FAQ;
