import { Dispatch, FC, ReactNode, SetStateAction, useEffect, useRef } from "react";

import { Box, Grid, Typography } from "@mui/material";
import Loader from "components/atoms/Loader";
import AlertWithTitle from "components/molecules/AlertWithTitle";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import SecondaryButton from "components/molecules/Buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Props {
	title: string;
	onSubmit: () => void;
	apiError: string | null;
	children: ReactNode;
	isMobile: boolean;
	isDirty: boolean;
	isValid: boolean;
	isSubmitting: boolean;
	submitButtonTitle: string;
	step?: number;
	changeStep: Dispatch<SetStateAction<number | undefined>>;
	showBackButton?: boolean;
}

const ChangeFormWrapper: FC<Props> = ({
	title,
	apiError,
	onSubmit,
	children,
	isDirty,
	isValid,
	isMobile,
	submitButtonTitle,
	isSubmitting,
	step,
	changeStep,
	showBackButton = false
}) => {
	const { t } = useTranslation();
	const ref = useRef<HTMLButtonElement>(null);
	const navigate = useNavigate();

	const handler = e => {
		if (e.code === "Enter") {
			ref?.current?.click();
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", handler);
		return () => {
			window.removeEventListener("keydown", handler);
		};
	}, []);

	return (
		<Box component="form" onSubmit={onSubmit}>
			<Typography variant="h5Headline" color="tertiary.main">
				{title}
			</Typography>
			{apiError && <AlertWithTitle margin={"32px 0px 0px"}>{apiError}</AlertWithTitle>}
			<Grid container columnSpacing={2} marginTop={{ xs: 1, md: 4 }}>
				{children}
			</Grid>
			<Box
				display="flex"
				flexDirection={{ md: "row-reverse", xs: "row" }}
				justifyContent={{ xs: "space-between", md: "start" }}
				gap={1}
				alignItems="center"
			>
				{((isMobile && step !== 1) || showBackButton) && (
					<SecondaryButton
						marginTop={16}
						shrinkButton
						onClick={() => {
							navigate("/profile");
							changeStep(1);
						}}
					>
						{t("account.buttons.back")}
					</SecondaryButton>
				)}
				<PrimaryButton
					ref={ref}
					type="submit"
					variant="contained"
					size="large"
					marginTop={16}
					disabled={!isDirty || !isValid}
					maxWidth={{ xs: isMobile ? 214 : "100%", md: 300 }}
				>
					{isSubmitting ? <Loader color="white" /> : submitButtonTitle}
				</PrimaryButton>
			</Box>
		</Box>
	);
};

export default ChangeFormWrapper;
