import { FC } from "react";

import { ButtonProps } from "@mui/material";
import Button from "components/atoms/Button";

import { buttonTypography } from "theme/buttonTypography";
const SecondaryButton: FC<ButtonProps & { marginTop?: number; shrinkButton?: boolean }> = ({
	children,
	fullWidth,
	marginTop = 0,
	shrinkButton = false,
	...rest
}) => (
	<Button
		variant="contained"
		size="large"
		sx={{
			...buttonTypography.large,
			maxWidth: fullWidth ? "100%" : 112,
			width: "100%",
			height: "48px",
			flex: shrinkButton ? 1 : "unset",
			marginTop: `${marginTop}px`,
			backgroundColor: theme => `${theme.palette.customShades.light3}`,
			color: theme => `${theme.palette.common.black}`,
			"&:hover": {
				backgroundColor: "customShades.light4",
				opacity: [0.9, 0.8, 0.7]
			},
			"&.Mui-disabled": {
				backgroundColor: theme => theme.palette.customShades.light3,
				color: theme => theme.palette.common.black,
				opacity: 0.4
			}
		}}
		{...rest}
	>
		{children}
	</Button>
);

export default SecondaryButton;
