import { FC, ReactNode } from "react";

import { Box, Grid, styled, useMediaQuery, useTheme } from "@mui/material";

import usePrefix from "hooks/usePrefix";
import { Link } from "react-router-dom";

import Logo from "assets/images/takeda-logo.svg";

const HeaderBox = styled(Grid)`
	height: 104px;
	width: 100%;
	padding: 27px 45px;
	border-top: ${({ theme }) => `16px solid ${theme.palette.primary.main}`};
	border-bottom: ${({ theme }) => `1px solid ${theme.palette.customShades.light3}`};
`;

const Header: FC<{ pushLogoLeft?: boolean; rightBlock?: ReactNode }> = ({ pushLogoLeft, rightBlock = null }) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const { linkPrefix } = usePrefix();

	return (
		<HeaderBox container justifyContent={pushLogoLeft && isDesktop ? "start" : "center"}>
			<Link to={`${linkPrefix}/signIn`}>
				<Box component="img" src={Logo} width={145} height={56} />
			</Link>
			{rightBlock && (
				<Box
					marginLeft={{ xs: 0, md: "auto" }}
					marginTop={{ xs: 8, md: 0 }}
					maxWidth={{ xs: "100%", md: 240 }}
					width="100%"
				>
					{rightBlock}
				</Box>
			)}
		</HeaderBox>
	);
};

export default Header;
