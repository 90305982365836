import { request } from "api/request";

import { BaseModel } from "models";

import { getTracingId } from "utils/getUniqueValue";

import { GLOBAL_CONSTANTS } from "../../../constants";
import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto, BaseUpdateDto } from "../../baseApiService";
import { URLS } from "../services.constants";

export class LogService extends BaseApiService<
	BaseModel,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	sendLog(data: unknown): Promise<void> {
		const key = getTracingId();
		return request(
			`${this.baseUrl}`,
			"POST",
			{ key, context: JSON.stringify(data) } as Record<string, unknown>,
			undefined,
			false,
			false,
			{
				Basic: GLOBAL_CONSTANTS.LOG_SECRET
			}
		) as Promise<void>;
	}
}

export const logService = new LogService(URLS.LOG);
