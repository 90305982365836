import { FC } from "react";

import { FormControl, TextFieldProps } from "@mui/material";
import InputLabel from "components/atoms/InputLabel";
import { FieldError } from "react-hook-form";

import { StyledTextField } from "./styles";

const TextFieldInput: FC<
	TextFieldProps & {
		validMessage?: string;
		error?: FieldError | boolean;
		errorMessage?: string | null;
		isPassword?: boolean;
	}
> = ({ label, name, error, required, validMessage, errorMessage, isPassword = false, ...rest }) => (
	<FormControl
		sx={{
			width: "100%",
			marginBottom: { xs: "24px", md: "16px" }
		}}
	>
		{label && (
			<InputLabel shrink htmlFor={name} required={required}>
				{label}
			</InputLabel>
		)}
		<StyledTextField
			id={name}
			error={!!error}
			helperText={error ? errorMessage || (error as FieldError)?.message : null}
			sx={{
				"& .MuiOutlinedInput-root": {
					"& fieldset": {
						borderColor: theme =>
							validMessage ? `${theme.palette.success.main}` : `${theme.palette.customShades.light4}`
					},
					"&.Mui-focused fieldset": {
						borderColor: theme => (validMessage ? `${theme.palette.success.main}` : `${theme.palette.common.black}`)
					}
				}
			}}
			{...rest}
			onBlur={e => {
				const value: string = isPassword ? e.target.value : e.target.value?.trim();
				const { onBlur, onChange } = rest;
				const event = { ...e, target: { ...e.target, value } };
				onChange && onChange(event);
				onBlur && onBlur(event);
			}}
		/>
	</FormControl>
);

export default TextFieldInput;
