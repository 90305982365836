import { isEqual } from "lodash";
import { TFunction } from "react-i18next";
import * as yup from "yup";

export interface ChangeProfileFormProps {
	title: string;
	firstName: string;
	lastName: string;
	preferredLanguage?: string;
	countryCode: string;
}

export interface ChangePasswordFormProps {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const profileValidationSchema = (t: TFunction<"translation", undefined>) =>
	yup.object().shape({
		title: yup.string(),
		firstName: yup
			.string()
			.required(t("account.form.fields.firstName.validation.required"))
			.min(2, t("account.form.fields.firstName.validation.min"))
			.max(100, t("account.form.fields.firstName.validation.max")),
		lastName: yup
			.string()
			.required(t("account.form.fields.lastName.validation.required"))
			.min(2, t("account.form.fields.lastName.validation.min"))
			.max(100, t("account.form.fields.lastName.validation.max")),
		preferredLanguage: yup.string().required(t("account.form.fields.preferredLanguage.validation.required")),
		countryCode: yup.string().required(t("account.form.fields.countryCode.validation.required"))
	});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const changePasswordValidationSchema = (t: TFunction<"translation", undefined>, isTakedaEmail?: boolean) =>
	yup.object().shape({
		oldPassword: yup
			.string()
			.required(t("changePasswordPage.fields.oldPassword.validation.required"))
			.typeError(t("changePasswordPage.fields.confirmPassword.validation.string")),
		newPassword: isTakedaEmail
			? yup
					.string()
					.required(t("changePasswordPage.fields.newPassword.validation.required"))
					.min(15, t("changePasswordPage.fields.newPassword.validation.minimum15"))
			: yup
					.string()
					.required(t("changePasswordPage.fields.newPassword.validation.required"))
					.min(8, t("changePasswordPage.fields.newPassword.validation.min"))
					.test("pattern", t("changePasswordPage.fields.newPassword.validation.pattern"), value =>
						[/[a-z]/, /[A-Z]/, /[0-9]/].every(pattern => value && pattern.test(value))
					)
					.typeError(t("changePasswordPage.fields.password.validation.string")),
		confirmPassword: yup
			.string()
			.required(t("changePasswordPage.fields.confirmPassword.validation.required"))
			.typeError(t("changePasswordPage.fields.confirmPassword.validation.string"))
			.when("newPassword", (password, schema) =>
				schema.test("matchPassword", t("changePasswordPage.fields.confirmPassword.validation.notEqual"), v =>
					isEqual(password, v)
				)
			)
	});
