import { Box, Typography } from "@mui/material";
import { Logo403 } from "assets";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import PageWithLogo from "components/templates/PageWithLogo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Error403 = () => {
	const { t } = useTranslation();
	return (
		<PageWithLogo>
			<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={3}>
				<Logo403 height="45%" />
				<Typography variant="h1Headline" color="common.black" mt={{ xs: 0, md: 8 }}>
					{t("errorPage.403.title")}
				</Typography>
				<Typography variant="textMedium" color="customShades.grey1" textAlign="center">
					{t("errorPage.403.description")}
				</Typography>
				<Link to="/signIn">
					<PrimaryButton variant="contained" type="submit" marginTop={40} maxWidth={400}>
						{t("errorPage.403.buttonTitle")}
					</PrimaryButton>
				</Link>
			</Box>
		</PageWithLogo>
	);
};

export default Error403;
