import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Typography } from "@mui/material";
import { CheckResponse, checkIfUserExists } from "api/features/Auth/authApi";
import ButtonFooter from "components/molecules/ButtonFooter";
import CheckBoxItem from "components/molecules/Inputs/CheckBoxInput";
import PasswordInput from "components/molecules/Inputs/PasswordInput";
import TextFieldInput from "components/molecules/Inputs/TextFieldInput";
import PasswordRegexValidator from "components/molecules/PasswordRegexValidator";
import FormWrapper from "components/templates/FormWrapper";
import { AppContext } from "contexts/app/AppProvider";
import { IFormOne } from "pages/SignUp/SignUp";
import { Controller, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { formTypography } from "theme/formTypography";

import { isTakedaAccount } from "utils/checkTakedaAccount";
import { validateRequiredFields } from "utils/validateRequiredFields";

const FormOne = ({ setActiveStep, formState, setFormState, buttonClick, setButtonClick }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const { hasHistory } = useContext(AppContext);
	const {
		handleSubmit,
		control,
		watch,
		getValues,
		trigger,
		formState: { errors, isValid }
	} = useForm<IFormOne>({
		mode: "onChange",
		delayError: 1000,
		defaultValues: formState
	});

	const [username, password] = watch(["username", "password"]);
	const specialEmail = useMemo(() => isTakedaAccount(username), [username]);

	const [emailExist, setEmailExist] = useState(false);

	const onSubmit = useCallback(
		(data: IFormOne) => {
			setIsLoading(true);
			if (!emailExist) {
				setFormState(data);
				setActiveStep(s => s + 1);
			}
			setIsLoading(false);
		},
		[emailExist, setActiveStep, setFormState]
	);

	useEffect(() => {
		if (buttonClick) {
			setButtonClick(false);
			handleSubmit(onSubmit)();
		}
	}, [buttonClick, handleSubmit, onSubmit, setButtonClick]);

	const checkEmailExist = useCallback(async value => {
		const response: CheckResponse = await checkIfUserExists(value);
		setEmailExist(false);
		if (response.success) {
			setEmailExist(true);
		}
	}, []);

	const isValidForm = validateRequiredFields(errors);

	return (
		<FormWrapper
			title={t("signUpPageRegular.title")}
			description={t("signUpPageRegular.subtitle")}
			footerBlock={
				<ButtonFooter
					isLoading={isLoading}
					secondaryButton={hasHistory}
					handleSecondaryButton={hasHistory ? () => navigate(-1) : undefined}
					primaryButtonText={t("buttons.continue")}
					handlePrimaryButton={handleSubmit(onSubmit)}
					disablePrimaryButton={!isValid}
				/>
			}
			onSubmit={handleSubmit(onSubmit)}
			errorSummary={!isValidForm ? t("signUpPageRegular.error") : ""}
		>
			<Typography variant="textMedium" color="tertiary.main" mb={3}>
				{t("signUpPageRegular.hcpQuestion")}
				<Typography
					display={{ xs: "block", md: "inline" }}
					variant="linkMedium"
					color="primary"
					component={Link}
					to="/countryList"
				>
					{t("signUpPageRegular.hcpActionTitle")}
				</Typography>
			</Typography>
			<Controller
				name="username"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<TextFieldInput
						placeholder={t("signUpPageRegular.email.placeholder")}
						variant="outlined"
						value={value}
						onBlur={async e => {
							checkEmailExist(e.target.value);
						}}
						onChange={onChange}
						label={t("signUpPageRegular.email.label")}
						error={!!error || emailExist}
						helperText={error ? error.message : emailExist ? t("signUpPageRegular.email.validation.exists") : null}
						type="email"
					/>
				)}
				rules={{
					required: true,
					validate: value =>
						[/\S+@\S+\.\S+/].every(pattern => pattern.test(value)) || t("signUpPageRegular.email.validation.format")
				}}
			/>
			<Controller
				name="password"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<PasswordInput
						value={value}
						placeholder={t("signUpPageRegular.password.placeholder")}
						label={t("signUpPageRegular.password.label")}
						onBlur={() => {
							trigger("confirmPassword");
						}}
						onChange={onChange}
						error={!!error}
						errorMessage={error ? error.message : null}
					/>
				)}
				rules={{
					required: true,
					minLength: {
						value: !specialEmail ? 8 : 15,
						message: !specialEmail
							? t("signUpPageRegular.password.validation.min")
							: t("signUpPageRegular.password.validation.exclusiveEmail")
					},
					validate: value =>
						!specialEmail
							? [/[a-z]/, /[A-Z]/, /[0-9]/].every(pattern => pattern.test(value)) ||
							  t("signUpPageRegular.password.validation.pattern")
							: undefined
				}}
			/>
			<Controller
				name="confirmPassword"
				control={control}
				defaultValue=""
				rules={{
					required: true,
					validate: {
						matchPassword: (val: string) => {
							return getValues().password === val || t("signUpPageRegular.confirmPassword.validation.notEqual");
						}
					}
				}}
				render={({ field: { onChange, value }, fieldState: { error, invalid, isDirty } }) => (
					<PasswordInput
						value={value}
						placeholder={t("signUpPageRegular.confirmPassword.placeholder")}
						label={t("signUpPageRegular.confirmPassword.label")}
						onChange={onChange}
						error={!!error}
						errorMessage={error ? error.message : null}
						validMessage={!invalid && isDirty ? t("signUpPageRegular.confirmPassword.validation.matched") : undefined}
					/>
				)}
			/>
			{!!password.length && <PasswordRegexValidator password={password} isTakedaEmail={specialEmail} />}
			<Controller
				name="termsAndPrivacy"
				control={control}
				defaultValue={false}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<CheckBoxItem
						name="termsAndPrivacy"
						onChange={onChange}
						value={value}
						label={
							<Typography color="tertiary.main" sx={{ ...formTypography.option.primary }}>
								{t("signUpPageRegular.termsAndPrivacy.label.agree")}
								<Link to="/terms-and-conditions" target="_blank">
									<Typography color="primary" component={"span"} sx={{ ...formTypography.option.primary }}>
										{t("signUpPageRegular.termsAndPrivacy.label.tc")}
									</Typography>
								</Link>
								<Typography color="tertiary.main" component={"span"} sx={{ ...formTypography.option.primary }}>
									{t("signUpPageRegular.termsAndPrivacy.label.and")}
								</Typography>
								<Link to="/privacy-notice" target="_blank">
									<Typography color="primary" component={"span"} sx={{ ...formTypography.option.primary }}>
										{t("signUpPageRegular.termsAndPrivacy.label.pp")}
									</Typography>
								</Link>
							</Typography>
						}
						error={error}
					/>
				)}
				rules={{ required: t("signUpPageRegular.termsAndPrivacy.validation.required") }}
			/>
		</FormWrapper>
	);
};

export default FormOne;
