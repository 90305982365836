import { useContext, useMemo } from "react";

import { Box, Grid, Typography, styled, useMediaQuery } from "@mui/material";

import { AppleStore } from "assets";
import PageWithLogo from "components/templates/PageWithLogo";
import { AppContext } from "contexts/app/AppProvider";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";

import { theme } from "theme";

import { SuccessAnimationData } from "assets/animations";

import { GLOBAL_CONSTANTS } from "../../../../constants";

const StyledContainer = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-content: center;
	align-items: center;
`;

const EmailActivated = ({ EmailActivatedState }) => {
	const { t } = useTranslation();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const { isRTL } = useContext(AppContext);
	const { pathname } = useLocation();

	const isConnect = useMemo(() => /.*connect-invitation.*/gim.test(pathname), [pathname]);
	if (EmailActivatedState.url.length > 0) {
		window.setTimeout(function () {
			window.location.replace(EmailActivatedState.url);
		}, 5000);
	}
	return (
		<PageWithLogo>
			<StyledContainer dir={isRTL ? "rtl" : "ltr"}>
				<Lottie
					options={{
						loop: false,
						autoplay: true,
						animationData: SuccessAnimationData,
						rendererSettings: {
							preserveAspectRatio: "xMidYMid slice"
						}
					}}
					height={212}
					width={217.8}
				/>
				<Grid
					container
					xs={7}
					spacing={1}
					paddingX={{ xs: 3, md: 0 }}
					direction="column"
					alignItems="center"
					justifyContent="center"
					maxWidth="100%"
				>
					<Grid item xs={12} display="flex" mt={4}>
						<Typography variant={isDesktop ? "h1Headline" : "h4Headline"} color="tertiary.main" align="center">
							{t("emailActivated.heading")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="textMedium" color="customShades.grey1" mt={3}>
							{t("emailActivated.subtitle")}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="textMedium" color="customShades.grey1" mt={3}>
							{t("emailActivated.subtitle2")}
						</Typography>
					</Grid>
					<Grid item xs={12} mt={3}>
						{isConnect && (
							<AppleStore onClick={() => window.open(GLOBAL_CONSTANTS.APPLE_STORE_URL)} style={{ cursor: "pointer" }} />
						)}
					</Grid>
				</Grid>
			</StyledContainer>
		</PageWithLogo>
	);
};

export default EmailActivated;
