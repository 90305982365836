import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const usePrefix = (): { linkPrefix: string; countryId?: string; defaultLanguage?: string } => {
	const { countryId, defaultLanguage } = useParams();
	const { i18n } = useTranslation();

	const linkPrefix = useMemo(() => {
		i18n.changeLanguage(defaultLanguage?.toLowerCase() || "en");
		return !!defaultLanguage && !!countryId ? `/${defaultLanguage}-${countryId}` : "";
	}, [countryId, defaultLanguage, i18n]);
	return { linkPrefix, countryId, defaultLanguage };
};

export default usePrefix;
