import { Grid, styled } from "@mui/material";

export const StyledSelectionContainer = styled(Grid)`
	border-width: 1px;
	border-style: solid;
	border-color: ${props => props.theme.palette.primary.main};
	border-radius: 8px;
	background-color: ${props => props.theme.palette.primary.main};
	background-color: #fdeeee;
	justify-content: space-between;
	display: flex;
	padding: 12px;
`;
