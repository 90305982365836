import { Box, Modal, Typography, styled } from "@mui/material";
import { IconDangerInfo } from "assets";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import SecondaryButton from "components/molecules/Buttons/SecondaryButton";
import { useTranslation } from "react-i18next";

import { GLOBAL_CONSTANTS } from "../../../constants";

const StyledModal = styled(Modal)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const Container = styled(Box)`
	outline: none;
	max-height: 480px;
	display: flex;
	justify-content: center;
	padding: 64px 32px;
	align-items: center;
	flex-direction: column;
	max-width: 800px;
	background-color: ${props => props.theme.palette.common.white};
	overflow: hidden;
	border-radius: 8px;
`;

const ButtonContainer = styled(Box)`
	display: flex;
	margin-top: 64px;
	gap: 8px;
	width: 450px;
`;

const ConsentModal = ({ open, setModal }) => {
	const { t } = useTranslation();

	return (
		<StyledModal open={open} onClose={setModal}>
			<Container>
				<IconDangerInfo />
				<Typography variant="h4Headline" color="tertiary.main" mt={5}>
					{t("account.consent.heading")}
				</Typography>
				<ButtonContainer>
					<SecondaryButton variant="contained" fullWidth shrinkButton onClick={() => setModal(false)}>
						{t("account.consent.buttons.goBack")}
					</SecondaryButton>
					<PrimaryButton
						type="submit"
						variant="contained"
						size="large"
						onClick={() => window.open(GLOBAL_CONSTANTS.CONSENT_URL)}
						maxWidth={320}
					>
						{t("account.consent.buttons.linkButton")}
					</PrimaryButton>
				</ButtonContainer>
			</Container>
		</StyledModal>
	);
};

export default ConsentModal;
