import { Box, Typography } from "@mui/material";
import { Logo404 } from "assets";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import PageWithLogo from "components/templates/PageWithLogo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Error404 = () => {
	const { t } = useTranslation();
	const storageStringValue = window.localStorage.getItem("okta-token-storage");
	const isAuthenticated = storageStringValue ? !!JSON.parse(storageStringValue)?.idToken : false;

	return (
		<PageWithLogo>
			<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={3}>
				<Logo404 height="45%" />
				<Typography variant="h1Headline" color="common.black" mt={{ xs: 0, md: 8 }}>
					{t("errorPage.404.title")}
				</Typography>
				<Typography variant="textMedium" color="customShades.grey1" textAlign="center">
					{t("errorPage.404.description")}
				</Typography>
				<Link to="/signIn">
					<PrimaryButton variant="contained" type="submit" marginTop={40} maxWidth={400}>
						{t(isAuthenticated ? "errorPage.404.buttonTitleGoHome" : "errorPage.404.buttonTitleGoLogin")}
					</PrimaryButton>
				</Link>
			</Box>
		</PageWithLogo>
	);
};

export default Error404;
