import "./index.css";

import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import MuiThemeProvider from "@mui/material/styles/ThemeProvider";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";

import AppRoutes from "core/AppRoutes";

import i18n from "./i18n/config";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<ThemeProvider theme={theme}>
					<StyleSheetManager stylisPlugins={[rtlPlugin]}>
						<AppRoutes />
					</StyleSheetManager>
				</ThemeProvider>
			</MuiThemeProvider>
		</I18nextProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
