import { Route, Routes } from "react-router-dom";

import { CreatePasswordForm, ForgotPasswordForm } from "./Forms";
import { PasswordChanged, SucceedSent } from "./containers";

const ForgotPassword = () => {
	return (
		<Routes>
			<Route path="/password-changed" element={<PasswordChanged />} />
			<Route path="/reset-password-email-sent" element={<SucceedSent />} />
			<Route path="/:recoveryToken" element={<CreatePasswordForm />} />
			<Route path="/" element={<ForgotPasswordForm />} />
		</Routes>
	);
};

export default ForgotPassword;
