import { FC } from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DynamicFooter from "components/atoms/DynamicFooter";
import Loader from "components/atoms/Loader";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";
import SecondaryButton from "components/molecules/Buttons/SecondaryButton";

interface ButtonGroupProps {
	onBack: () => void;
	onSubmit: () => Promise<void>;
	submitTitle: string;
	backTitle: string;
	dirty: boolean;
	valid: boolean;
	loading: boolean;
	isBackButtonEnabled?: boolean;
}

const ButtonGroup: FC<ButtonGroupProps> = ({
	backTitle,
	isBackButtonEnabled,
	dirty,
	loading,
	onBack,
	onSubmit,
	submitTitle,
	valid
}) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	return (
		<DynamicFooter
			width={isDesktop ? "69.166667%" : "100%"}
			justifyContent={{ xs: "space-between", md: "flex-end" }}
			flexDirection="row"
			flexWrap="wrap-reverse"
		>
			{isBackButtonEnabled && (
				<SecondaryButton variant="contained" onClick={onBack} fullWidth={!isDesktop}>
					{backTitle}
				</SecondaryButton>
			)}
			<PrimaryButton
				type="submit"
				onClick={onSubmit}
				variant="contained"
				size="large"
				maxWidth={isDesktop ? "auto" : "100%"}
				disabled={!dirty || !valid}
			>
				{loading ? <Loader color="white" /> : submitTitle}
			</PrimaryButton>
		</DynamicFooter>
	);
};

export default ButtonGroup;
