import { AlertTitle } from "@mui/material";
import Alert from "components/atoms/Alert";

const AlertWithTitle = ({ children, margin = "0px" }) => (
	<Alert margin={margin}>
		<AlertTitle sx={{ color: theme => theme.typography.textMedium }}>{children}</AlertTitle>
	</Alert>
);

export default AlertWithTitle;
