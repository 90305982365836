import { useEffect } from "react";

import { continueSignIn } from "api/features/Auth/authApi";
import Loader from "components/atoms/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";

const ExternalAuth = () => {
	const navigate = useNavigate();
	const [params] = useSearchParams();

	useEffect(() => {
		if (params.get("code")) {
			continueSignIn(
				state => navigate("/profile", { state }),
				() => {}
			);
		} else {
			navigate("/");
		}
	}, [navigate, params]);

	return <Loader color="white" />;
};

export default ExternalAuth;
