import { FormControl, FormHelperText } from "@mui/material";
import InputLabel from "components/atoms/InputLabel";
import { DeepMap, FieldValues, Message, MultipleFieldErrors, Ref, useController } from "react-hook-form";

import Select from "../Select";
export type FieldError = {
	type: string;
	ref?: Ref;
	types?: MultipleFieldErrors;
	message?: Message;
};
export type FieldErrors<TFieldValues extends FieldValues = FieldValues> = DeepMap<TFieldValues, FieldError>;

const OutlinedSelectField = props => {
	const { options, label, name, control, required, rules, defaultValue, ...rest } = props;
	const {
		field: { value, onChange, onBlur },
		fieldState: { error }
	} = useController({
		name,
		control,
		rules: rules,
		defaultValue: defaultValue || ""
	});

	return (
		<FormControl sx={{ width: "100%", marginBottom: { xs: "24px", md: "16px" } }}>
			{label && (
				<InputLabel shrink htmlFor={name}>
					{label}
				</InputLabel>
			)}
			<Select
				value={value}
				options={options}
				required={required}
				fieldLabel={label}
				error={!!error}
				onChange={onChange}
				onBlur={onBlur}
				{...rest}
			/>
			{!!error && (
				<FormHelperText error id={name} sx={{ margin: 0 }} required={!!required}>
					{error?.message}
				</FormHelperText>
			)}
		</FormControl>
	);
};

export default OutlinedSelectField;
