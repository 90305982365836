import { FC } from "react";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Greeting: FC<{ isMobile: boolean; firstName: string; lastName: string }> = ({
	isMobile,
	firstName,
	lastName
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Typography variant="h4Headline" color="tertiary.main">{`${t(
				isMobile ? "account.greeting.short" : "account.greeting.full"
			)}, ${firstName} ${lastName}`}</Typography>
			<br />
			<Typography variant="textMedium" color="customShades.grey1">
				{t("account.greeting.welcome")}
			</Typography>
		</>
	);
};

export default Greeting;
