import { styled } from "@mui/material";
import TextField from "components/atoms/TextField";

export const StyledTextField = styled(TextField)`
	box-sizing: border-box;

	& .Mui-error {
		input {
			background-color: ${props => props.theme.palette.common.white};
		}
	}

	input {
		height: 16px;
		padding-inline-start: 1em;
		color: #000;
		border-radius: 4px;
		line-height: 1;
		font-family: "InterVariable";
		font-size: 16px;
		font-weight: 500;
		font-style: normal;
		text-align: start;

		&::placeholder {
			color: ${props => props.theme.palette.customShades.grey1};
			opacity: 1;
			font-family: "InterVariable";
			font-size: 16px;
			font-weight: 400;
			font-style: normal;
			text-align: start;
		}

		&.Mui-disabled {
			-webkit-text-fill-color: ${props => props.theme.palette.common.black};
		}
	}

	fieldset {
		border-color: ${props => props.theme.palette.customShades.light4};
	}

	&.MuiFormControl-root {
		border-radius: 4px;

		.Mui-error {
			background-color: ${props => props.theme.palette.common.white};
		}

		background-color: ${props => props.theme.palette.customShades.light2};

		&:hover {
			background-color: ${props => props.theme.palette.customShades.light3};

			input {
				background-color: ${props => props.theme.palette.customShades.light3};
			}

			.Mui-error {
				input {
					background-color: ${props => props.theme.palette.common.white};
				}
			}
		}

		&:focus-within {
			background-color: ${props => props.theme.palette.common.white};

			input {
				background-color: ${props => props.theme.palette.common.white};
			}
		}
	}

	& .MuiOutlinedInput-root {
		&.MuiOutlinedInput-root:hover {
			& > fieldset {
				border-color: ${props => props.theme.palette.customShades.light4};
				border-width: 2px;
				border-style: solid;
				border-radius: 4px;
			}
		}

		&.Mui-focused fieldset {
			border-color: ${props => props.theme.palette.common.black};
			border-width: 1px;
			border-radius: 4px;
		}
	}

	& .MuiFormHelperText-root {
		margin-inline-start: 0;
		margin-inline-end: 0;
		background-color: ${props => props.theme.palette.background.default};
		margin-top: 0;
		padding-top: 3px;
	}
`;
