import { FC, ReactNode, useContext, useEffect, useState } from "react";

import { checkIfUserSignedIn } from "api/features/Auth/authApi";
import { AppContext } from "contexts/app/AppProvider";
import logger from "loggers";
import { useLocation, useNavigate } from "react-router-dom";

import { urlList } from "./constant";

const SmartRedirect: FC<{ children: ReactNode }> = ({ children }) => {
	const [counter, setCounter] = useState<number>(0);
	const [rootKey, setRootKey] = useState<string | undefined>();
	const navigate = useNavigate();
	const location = useLocation();
	const { hasHistory, setHasHistory } = useContext(AppContext);

	useEffect(() => {
		window.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
			logger.error({ event, source, lineno, colno, error });
		};
	}, []);

	useEffect(() => {
		setCounter(c => c + 1);
	}, [location.key]);

	useEffect(() => {
		if (hasHistory === undefined && location.pathname !== "/" && counter === 1) {
			setRootKey(location.key);
			setHasHistory(false);
		} else if (hasHistory && location.key === rootKey) {
			setHasHistory(false);
			setRootKey(location.key);
		}
	}, [counter, hasHistory, location.key, location.pathname, rootKey, setHasHistory]);

	useEffect(() => {
		if (counter >= 2 && hasHistory !== undefined && location.key !== rootKey) {
			setHasHistory(true);
		}
	}, [counter, hasHistory, location.key, rootKey, setHasHistory]);

	useEffect(() => {
		if (location.pathname === "/") {
			checkIfUserSignedIn(
				() => navigate("/profile"),
				() => navigate("/self-registration")
			);
		} else if (urlList.some(v => location.pathname.toLowerCase().includes(v))) {
			checkIfUserSignedIn(
				() => navigate("/profile"),
				() => {}
			);
		} else if (location.pathname.toLowerCase() === "/profile") {
			checkIfUserSignedIn(
				() => () => {},
				() => navigate("/signIn")
			);
		}
	}, [counter, location.pathname, navigate]);

	return <>{children}</>;
};

export default SmartRedirect;
