import { FC, useContext, useState } from "react";

import {
	FormControl,
	Select as MSelect,
	styled as MStyled,
	MenuItem,
	SelectProps as MuiSelectProps,
	useMediaQuery
} from "@mui/material";
import { AppContext } from "contexts/app/AppProvider";
import styled, { css } from "styled-components";

import { theme } from "theme";

import { formTypography } from "theme/formTypography";

import SelectModal from "../SelectModal";

const Container = styled.div`
	.MuiOutlinedInput-root {
		width: 100%;
		border-radius: 0.2em;
		box-sizing: border-box;
		font-family: "InterVariable";
		font-size: 16px;
		font-weight: 500;
		font-style: normal;
		text-align: start;
		position: relative;
		overflow: hidden;
		height: 48px;
		color: ${props => props.theme.palette.customShades.grey1};

		${({ hasValue, theme }) =>
			css`
				color: ${hasValue ? theme.palette.tertiary.main : theme.palette.customShades.grey1};
			`}

		fieldset {
			border-color: ${props => props.theme.palette.customShades.light4};
		}
		&.Mui-focused fieldset {
			border-color: ${props => props.theme.palette.common.black};
			border-width: 1px;
			border-radius: 4px;
		}
		&:hover fieldset {
			border-color: ${props => props.theme.palette.customShades.light4};
			border-width: 2px;
			border-style: solid;
			border-radius: 4px;
		}
	}
	& .Mui-error {
		& .MuiSelect-select {
			background: ${props => props.theme.palette.common.white};
		}
	}
`;
export const StyledMenuItem = MStyled(MenuItem)`
	height: 48px;

  &.MuiMenuItem-root {
     color: ${props => props.theme.palette.tertiary.main};
     &.Mui-selected{
      background-color: ${props => props.theme.palette.common.white};
     }
     &:hover{
      background-color: ${props => props.theme.palette.primary.main};
      color: ${props => props.theme.palette.common.white};
     }
   }`;

export interface IOptions {
	label: string;
	value: string | number;
}

export interface SelectProps extends MuiSelectProps {
	options: IOptions[];
	fieldLabel?: string;
	onChange: (e) => void;
}

const Select: FC<SelectProps> = ({ options, name, placeholder, value, fieldLabel, required, onChange, ...props }) => {
	const { isRTL } = useContext(AppContext);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [modal, setModal] = useState(false);
	const [openSelect, setOpenSelect] = useState(false);
	const handleClose = () => {
		setModal(false);
		setOpenSelect(false);
	};

	const handleOpen = e => {
		e.preventDefault();
		if (isMobile) {
			return setModal(true);
		}
		return setOpenSelect(true);
	};

	return (
		<>
			<FormControl style={{ width: "100%" }}>
				<Container hasValue={!!value}>
					<MSelect
						{...props}
						id={name}
						displayEmpty
						name={name}
						value={value}
						onOpen={handleOpen}
						onClose={handleClose}
						open={openSelect}
						MenuProps={{
							MenuListProps: {
								sx: {
									boxShadow: "0px 4px 10px 0px RGBA(0, 0, 0, 0.05)"
								}
							}
						}}
						sx={{
							...formTypography.placeholder.medium
						}}
					>
						{placeholder && (
							<StyledMenuItem
								value=""
								onClick={() => {
									onChange("");
								}}
								disabled={!!required}
								dir={isRTL ? "rtl" : "ltr"}
								sx={{ ...formTypography.option.primary }}
							>
								{placeholder}
							</StyledMenuItem>
						)}
						{options.map(({ value: _value, label }, index) => (
							<StyledMenuItem
								key={index}
								dir={isRTL ? "rtl" : "ltr"}
								value={_value}
								onClick={() => {
									onChange(_value);
								}}
								sx={{ ...formTypography.option.primary }}
							>
								{label}
							</StyledMenuItem>
						))}
					</MSelect>
				</Container>
			</FormControl>
			<SelectModal
				open={modal}
				onClose={handleClose}
				title={fieldLabel}
				options={options}
				required={required}
				onChange={onChange}
				placeholder={placeholder}
			/>
		</>
	);
};

export default Select;
