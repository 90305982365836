let cashedToken = "";

const TOKEN_KEY = "app_token";
const REFRESH_TOKEN_KEY = "app_refreshToken";
const OKTA_CACHE_STORAGE = "okta-cache-storage";
const OKTA_TOKEN_STORAGE = "okta-token-storage";

const setJwt = (token: string, refreshToken?: string): void => {
	cashedToken = token;
	localStorage.setItem(TOKEN_KEY, token);
	if (refreshToken) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	}
};

const getJwt = (includeRefreshToken?: boolean): string | null => {
	const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
	const sessionToken = cashedToken || localStorage.getItem(TOKEN_KEY);
	if (!sessionToken) {
		return null;
	}
	return (
		`${sessionToken}` +
		`${refreshToken && refreshToken.length && includeRefreshToken !== false ? ` ${refreshToken}` : ""}`
	);
};

const injectJwt = (config: Record<string, unknown> = {}): Record<string, unknown> => {
	const token = getJwt();
	const header = token ? { Authorization: token } : {};
	const configHeaders = (config && (config.headers as Record<string, unknown>)) || {};
	return {
		...config,
		headers: {
			"Content-Type": "application/json",
			...configHeaders,
			...header
		}
	};
};

const clearJwt = (): void => {
	cashedToken = "";
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(REFRESH_TOKEN_KEY);
	localStorage.removeItem(OKTA_CACHE_STORAGE);
	localStorage.removeItem(OKTA_TOKEN_STORAGE);
};

export { setJwt, getJwt, injectJwt, clearJwt /*, getWorkspace*/ };
