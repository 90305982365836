import { useContext } from "react";

import { Box, Grid, Typography, styled } from "@mui/material";
import { AppContext } from "contexts/app/AppProvider";
import usePrefix from "hooks/usePrefix";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import backgroundImage from "assets/images/leftside-image.webp";

export const StyledImageContainer = styled(Box)`
	height: 100vh;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(16, 20, 38, 0.3) 100%), url(${backgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 80%;
	@media (min-height: 1100px) {
		background-position: bottom;
	}
`;

export const StyledImageTextContainer = styled(Box)`
	padding: 96px 24px 24px;
	width: 100%;
`;
export const StyledImageText = styled(Box)`
	display: flex;
	padding-inline-start: 24px;
	flex-direction: column;
	border-inline-start: ${props => `4px solid ${props.theme.palette.primary.main}`};
`;

const LegalLinksWrapper = styled(Grid)`
	display: flex;
	width: auto;
`;

const SideInfo = ({}) => {
	const { t } = useTranslation();
	const { isRTL } = useContext(AppContext);

	const { linkPrefix, countryId } = usePrefix();

	return (
		<StyledImageContainer>
			<StyledImageTextContainer>
				<StyledImageText dir={isRTL ? "rtl" : "ltr"}>
					<Typography variant="h4Headline" color="tertiary.main">
						{t("sideInfo.description")}
					</Typography>
					<Typography
						variant="linkLead"
						color="primary.main"
						component={Link}
						to={`${linkPrefix}/faq`}
						target="_blank"
						width="fit-content"
					>
						{t("sideInfo.learnMore")}
					</Typography>
					<Box
						position="fixed"
						bottom={0}
						left={0}
						width="30.77%"
						display="flex"
						flexDirection="column"
						alignItems="flex-start"
						padding="32px 24px"
					>
						<LegalLinksWrapper item xs={12} justifyContent={{ xs: "center", md: "flex-end" }} gap={3}>
							<Typography
								variant="linkSmall"
								color="common.white"
								target="_blank"
								component={Link}
								to={`${linkPrefix}/terms-and-conditions`}
							>
								{t("footer.termOfUseLinkTitle")}
							</Typography>
							<Typography
								variant="linkSmall"
								color="common.white"
								target="_blank"
								component={Link}
								to={`${linkPrefix}/privacy-notice`}
							>
								{t("footer.privacyPolicyLinkTitle")}
							</Typography>
							<Typography
								variant="linkSmall"
								color="common.white"
								target="_blank"
								component={Link}
								to={`${linkPrefix}/faq`}
							>
								{t("footer.faq")}
							</Typography>
						</LegalLinksWrapper>
						{countryId === "gb" && (
							<Typography variant="textSmall" color="common.white" mt={1.5}>
								{t("footer.buildLegalText")}
							</Typography>
						)}
						<Typography variant="textSmall" color="common.white" mt={1.5}>
							&copy; {t("footer.copy")}
						</Typography>
					</Box>
				</StyledImageText>
			</StyledImageTextContainer>
		</StyledImageContainer>
	);
};

export default SideInfo;
