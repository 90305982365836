import { useCallback, useContext, useMemo, useState } from "react";

import { Box, ClickAwayListener, Link as MuiLink, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CheckResponse, checkIfUserExists } from "api/features/Auth/authApi";
import { IconInfo } from "assets";
import ButtonFooter from "components/molecules/ButtonFooter";
import CheckBoxItem from "components/molecules/Inputs/CheckBoxInput";
import PasswordInput from "components/molecules/Inputs/PasswordInput";
import TextFieldInput from "components/molecules/Inputs/TextFieldInput";
import PasswordRegexValidator from "components/molecules/PasswordRegexValidator";
import FormWrapper from "components/templates/FormWrapper";
import { AppContext } from "contexts/app/AppProvider";
import { links } from "data";
import usePrefix from "hooks/usePrefix";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { formTypography } from "theme/formTypography";
import { isTakedaAccount } from "utils/checkTakedaAccount";
import { validateRequiredFields } from "utils/validateRequiredFields";

export interface IFormOne {
	username: string;
	password: string;
	confirmPassword: string;
	termsAndPrivacy: boolean;
	emailCommunication: boolean;
}

export const EmailCheckBoxContainer = styled(Box)`
	display: flex;
	gap: 4px;
	width: 100%;
	align-items: center;
`;

const FormOne = ({ formOneState, setFormOneState, setActiveStep, checkCRMUserExist }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const [toolTipOpen, setToolTipOpen] = useState(false);

	const { isRTL } = useContext(AppContext);

	const { linkPrefix, countryId } = usePrefix();

	const [emailExist, setEmailExist] = useState(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const checkEmailExist = useCallback(async value => {
		const response: CheckResponse = await checkIfUserExists(value);
		setEmailExist(false);
		if (response.success) {
			setEmailExist(true);
		}
	}, []);

	const {
		control,
		handleSubmit,
		watch,
		trigger,
		formState: { errors, isValid }
	} = useForm<IFormOne>({
		mode: "onChange",
		delayError: 1000,
		defaultValues: formOneState
	});

	const [username, password] = watch(["username", "password"]);
	const specialEmail = useMemo(() => isTakedaAccount(username), [username]);

	const onSubmit = (data: IFormOne) => {
		setIsLoading(true);
		if (!emailExist) {
			setFormOneState(data);
			checkCRMUserExist({
				email: username,
				crm_country: countryId!
			});
			setActiveStep(step => step + 0);
		}
		setIsLoading(false);
	};

	const tooltipText = () => {
		return countryId === "ca" ? t("signUpPageHCP.tooltipText.ca") : t("signUpPageHCP.tooltipText.default");
	};

	const isValidForm = validateRequiredFields(errors);

	return (
		<FormWrapper
			title={t("signUpPageHCP.title")}
			description={t("signUpPageHCP.subtitle")}
			footerBlock={
				<ButtonFooter
					isLoading={isLoading}
					primaryButtonText={t("buttons.continue")}
					handlePrimaryButton={handleSubmit(onSubmit)}
					disablePrimaryButton={!isValid}
					secondaryButton={true}
					handleSecondaryButton={() => navigate(-1)}
				/>
			}
			onSubmit={handleSubmit(onSubmit)}
			errorSummary={!isValidForm ? t("signUpPageHCP.error") : ""}
		>
			<Controller
				name="username"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<TextFieldInput
						placeholder={t("signUpPageHCP.email.placeholder")}
						variant="outlined"
						value={value}
						onBlur={async e => {
							checkEmailExist(e.target.value);
						}}
						onChange={onChange}
						label={t("signUpPageHCP.email.label")}
						error={!!error || emailExist}
						helperText={error ? error.message : emailExist ? t("signUpPageHCP.email.validation.exists") : null}
						type="email"
					/>
				)}
				rules={{
					required: true,
					validate: value =>
						[/\S+@\S+\.\S+/].every(pattern => pattern.test(value)) || t("signUpPageHCP.email.validation.format")
				}}
			/>
			<Controller
				name="password"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<PasswordInput
						value={value}
						placeholder={t("signUpPageHCP.password.placeholder")}
						label={t("signUpPageHCP.password.label")}
						onChange={onChange}
						onBlur={() => {
							trigger("confirmPassword");
						}}
						error={!!error}
						errorMessage={error ? error.message : null}
					/>
				)}
				rules={{
					required: true,
					minLength: {
						value: !specialEmail ? 8 : 15,
						message: !specialEmail
							? t("signUpPageHCP.password.validation.min")
							: t("signUpPageRegular.password.validation.exclusiveEmail")
					},
					validate: value =>
						!specialEmail
							? [/[a-z]/, /[A-Z]/, /[0-9]/].every(pattern => pattern.test(value)) ||
							  t("signUpPageHCP.password.validation.pattern")
							: undefined
				}}
			/>
			<Controller
				name="confirmPassword"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value }, fieldState: { error, invalid, isDirty } }) => (
					<PasswordInput
						value={value}
						placeholder={t("signUpPageHCP.confirmPassword.placeholder")}
						label={t("signUpPageHCP.confirmPassword.label")}
						onChange={onChange}
						error={!!error}
						errorMessage={error ? error.message : null}
						validMessage={!invalid && isDirty ? t("signUpPageHCP.confirmPassword.validation.matched") : undefined}
					/>
				)}
				rules={{
					required: true,
					validate: (val: string) => password === val || t("signUpPageHCP.confirmPassword.validation.notEqual")
				}}
			/>
			{!!password.length && <PasswordRegexValidator password={password} isTakedaEmail={specialEmail} />}
			<Controller
				name="termsAndPrivacy"
				control={control}
				defaultValue={false}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<CheckBoxItem
						name="termsAndPrivacy"
						onChange={onChange}
						value={value}
						label={
							<Typography color="tertiary.main" sx={{ ...formTypography.option.primary }}>
								{t("signUpPageHCP.termsAndPrivacy.label.agree")}
								<Link to={`${linkPrefix}/terms-and-conditions`} target="_blank">
									<Typography color="primary" component={"span"} sx={{ ...formTypography.option.primary }}>
										{t("signUpPageHCP.termsAndPrivacy.label.tc")}
									</Typography>
								</Link>
								<Typography color="tertiary.main" component={"span"} sx={{ ...formTypography.option.primary }}>
									{t("signUpPageHCP.termsAndPrivacy.label.and")}
								</Typography>
								<Link to={`${linkPrefix}/privacy-notice`} target="_blank">
									<Typography color="primary" component={"span"} sx={{ ...formTypography.option.primary }}>
										{t("signUpPageHCP.termsAndPrivacy.label.pp")}
									</Typography>
								</Link>
							</Typography>
						}
						error={error}
					/>
				)}
				rules={{ required: t("signUpPageHCP.termsAndPrivacy.validation.required") }}
			/>
			{countryId?.toLowerCase() != "it" && countryId?.toLowerCase() != "us" && (
				<Controller
					name="emailCommunication"
					control={control}
					defaultValue={false}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<CheckBoxItem
							name="emailCommunication"
							onChange={onChange}
							value={value}
							label={
								<EmailCheckBoxContainer>
									<Typography color="tertiary.main" sx={{ ...formTypography.option.primary }}>
										{t("signUpPageHCP.emailCommunication.permission")}
									</Typography>
									<ClickAwayListener onClickAway={() => setToolTipOpen(false)}>
										<Tooltip
											onClick={e => {
												e.preventDefault();
												setToolTipOpen(s => !s);
											}}
											open={toolTipOpen}
											onClose={() => setToolTipOpen(false)}
											onOpen={() => setToolTipOpen(true)}
											arrow
											title={<Box dir={isRTL ? "rtl" : "ltr"}>{tooltipText()}</Box>}
										>
											<IconInfo
												width={isDesktop ? "20" : "36"}
												height={isDesktop ? "20" : "36"}
												fill={theme.palette.customShades.grey1}
											/>
										</Tooltip>
									</ClickAwayListener>
								</EmailCheckBoxContainer>
							}
							error={error}
						/>
					)}
				/>
			)}

			{countryId === "ca" && (
				<Typography color="customShades.grey1" variant="textSmall" ml={4}>
					{t("signUpPageHCP.moreInfo")}
					<Typography color="primary" component={MuiLink} target="_blank" href={links.whoWeAre} variant="linkSmall">
						{t("signUpPageHCP.thisLink")}
					</Typography>
				</Typography>
			)}
		</FormWrapper>
	);
};

export default FormOne;
