import { FormControlLabel, FormHelperText, styled } from "@mui/material";
import Checkbox from "components/atoms/Checkbox";

const StyledFormControlLabel = styled(FormControlLabel)`
	margin-inline-start: -11px;
	margin-inline-end: 16px;
	.MuiCheckbox-root {
		color: ${props => props.theme.palette.customShades.light5};
	}
`;

const CheckBoxItem = ({ name, label, value, error, ...props }) => (
	<>
		<StyledFormControlLabel control={<Checkbox name={name} checked={value} {...props} />} label={label} />
		{error && <FormHelperText error>{error.message}</FormHelperText>}
	</>
);

export default CheckBoxItem;
