import { FC, useCallback, useEffect, useState } from "react";

import { Box, CircularProgress, Grid, useMediaQuery } from "@mui/material";

import { fetchCountries, fetchLanguages } from "api/features/Auth/authApi";
import { getCurrentUserData } from "api/features/User/userApi";
import Footer from "components/atoms/Footer";
import PageWithLogo from "components/templates/PageWithLogo";
import { Profile } from "models";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { ChangePasswordForm, ChangeProfileForm } from "./Forms";
import { ButtonBlock, Greeting, UserData } from "./components";

import ConsentScreen from "./components/ConsentScreen";
import { IOption } from "./models";

const ProfilePage: FC = () => {
	const isDesktop = useMediaQuery("(min-width:600px)");
	const navigate = useNavigate();

	const [profile, setProfile] = useState<Profile>();
	const [step, setStep] = useState(isDesktop ? undefined : 1);

	const [languageOptions, setLanguageOptions] = useState<IOption[]>([]);
	const [countryOptions, setCountriesOptions] = useState<IOption[]>([]);

	const getLanguageOptions = useCallback(async () => {
		const apiData = await fetchLanguages();
		setLanguageOptions(apiData.map(({ code: value, name: label }) => ({ value, label })));
		return;
	}, []);

	const getCountriesOptions = useCallback(async () => {
		const apiData = await fetchCountries();
		setCountriesOptions(apiData.map(({ code: value, name: label }) => ({ value, label })));
		return;
	}, []);

	const loadProfile = useCallback(async () => {
		const user = await getCurrentUserData();
		setProfile(user.profile);
	}, []);

	useEffect(() => {
		getCountriesOptions();
		getLanguageOptions();
		loadProfile();
	}, [getCountriesOptions, getLanguageOptions, loadProfile]);

	if (!profile)
		return (
			<Box width="100%" height="50%" display="flex" alignItems="center" justifyContent="center">
				<CircularProgress size={100} />
			</Box>
		);

	return (
		<PageWithLogo pushLogoLeft={isDesktop} displayFooter={false}>
			<Grid
				display="grid"
				gridTemplateColumns={{ md: "1fr 2fr", xs: "1fr" }}
				maxHeight={isDesktop ? "calc(100vh - 128px)" : "100%"}
			>
				{(isDesktop || step === 1) && (
					<Grid
						container
						flexDirection="column"
						justifyContent="space-between"
						padding={5}
						paddingBottom={{ xs: 0, md: 5 }}
					>
						<Box mb={{ xs: 5, md: 0 }}>
							<Greeting {...profile} isMobile={!isDesktop} />
							<UserData profile={profile} countryOptions={countryOptions} languageOptions={languageOptions} />
						</Box>
						<ButtonBlock
							isDesktop={isDesktop}
							onEditProfile={() => setStep(2)}
							onChangePassword={() => {
								navigate("/profile/change-password");
								if (!isDesktop) {
									setStep(2);
								}
							}}
							onConsent={() => {
								navigate("/profile/consent-redirect");
								if (!isDesktop) {
									setStep(2);
								}
							}}
						/>
					</Grid>
				)}
				<Grid overflow="scroll" bgcolor="customShades.light3">
					<Box padding={{ md: 3 }} height="100%" bgcolor="customShades.light2">
						<Box
							borderRadius={1}
							bgcolor="common.white"
							height={isDesktop ? "calc(100% - 40px)" : "100%"}
							overflow="scroll"
							padding={{ xl: 10, lg: 5, md: 4, xs: 3 }}
						>
							{(isDesktop || step === 2) && (
								<Routes>
									<Route
										path="/"
										element={
											<ChangeProfileForm
												profile={profile}
												updateProfile={setProfile}
												countryOptions={countryOptions}
												languageOptions={languageOptions}
												isMobile={!isDesktop}
												step={step}
												changeStep={setStep}
											/>
										}
									/>
									<Route
										path="/change-password"
										element={
											<ChangePasswordForm
												changeStep={setStep}
												step={step}
												isMobile={!isDesktop}
												userName={profile.email}
											/>
										}
									/>
									<Route path="/consent-redirect" element={<ConsentScreen changeStep={setStep} />} />
									<Route path="*" element={<Navigate to="/not-found" />} />
								</Routes>
							)}
						</Box>
					</Box>
				</Grid>
			</Grid>
			<Footer fullWidth={false} />
		</PageWithLogo>
	);
};

export default ProfilePage;
